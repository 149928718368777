import { PropsWithChildren, useState } from "react";
import { IDetailsContentCollapsible } from "../../types/Components";
import * as ProgramUtils from "../../utils/programUtils";
import Collapsible from "react-collapsible";

const DetailsContentCollapsible = (props: PropsWithChildren<IDetailsContentCollapsible>) => {
  const [isOpen, setIsOpen] = useState(false);

  const showTrustLabel = ProgramUtils.showTrustLabelForDetailsCollapsible(props.program.ID);

  function getBackgroundColor() {
    switch (props.program.ID) {
      case "LR0HYZYWY6JQK":
        return "bg-NWAS";
      case "LNA4FTCKB003M":
      case "LNA4FTCKB004M":
      case "LQGK18YODQ6D2":
        return "bg-LAS";
      default:
        return "bg-black";
    }
  }

  return (
    <Collapsible
      onOpening={() => setIsOpen(true)}
      onClosing={() => setIsOpen(false)}
      trigger={
        <>
          <div className="flex">
            {showTrustLabel && (
              <div className={`text-white px-1 rounded-[2px] font-semibold text-13px ${getBackgroundColor()}`}>
                {props.program.TrustName ?? "XXX"}
              </div>
            )}
            {props.program.showBetaLabel && (
              <div className="text-white bg-black px-1 rounded-[2px] font-semibold text-13px ml-auto">{"BETA"}</div>
            )}
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="text-acc-17px font-extrabold text-grey-80">{props.title}</div>
            <div className="text-[13px] font-semibold tracking-[-0.05px] text-cta-blue acc-underline">
              {`${isOpen ? "Hide" : "View"} details`}
            </div>
          </div>
        </>
      }
    >
      {props.children}
    </Collapsible>
  );
};

export default DetailsContentCollapsible;
