import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonMenuButton,
  IonModal,
  IonPage,
  IonPopover,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Button_Cancel,
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Button_Filter,
  Button_Filter_Active,
  Button_Menu,
  Button_Search,
  Button_Search_Active,
  Button_Tickbox_3_Inactive,
  Icon_Search_Small,
  Image_Evidence_Empty_State,
} from "../../assets/images";
import "./Evidence.css";
import "../../App.css";
import EvidenceContainer from "../../components/evidence/EvidenceContainer";
import { useRecoilStateLoadable, useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  AllProgressCheckStatuses,
  IAppFeatures,
  IEvidence,
  IEvidenceDraft,
  IEvidenceView,
  IExportType,
  IUser,
  ProgramData,
  UsageData,
} from "../../Interfaces";
import {
  appFeaturesAtom,
  draftsHintDismissedAtom,
  evidenceAtom,
  evidenceDraftsAtom,
  progressCheckDataAtom,
  userAtom,
  userProgramsAtom,
} from "../../state/State";
import { format } from "date-fns";
import _ from "lodash";
import { EventRegister } from "react-native-event-listeners";
import { useHistory, withRouter } from "react-router";
import { pdf, Document } from "@react-pdf/renderer";
import ExportedPDF from "../../components/pdfExport/ExportedPDF";
import { FirebaseService } from "../../controllers/FirebaseService";
import { saveAs } from "file-saver";
import DataController from "../../controllers/DataController";
import { Directory } from "@capacitor/filesystem";
import WriteBlob from "capacitor-blob-writer";
import { FileOpener } from "@capacitor-community/file-opener";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";
import { Capacitor } from "@capacitor/core";
import { ExportButton, ExportButtonContainer, HeaderTitle } from "parafolio-components";
import EvidenceDraftsHint from "../../components/evidence/EvidenceDraftsHint";
import { Preferences } from "@capacitor/preferences";
import EvidenceSearchCard from "../../components/evidence/EvidenceSearchCard";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import * as ProgramUtils from "../../utils/programUtils";
import BlankEvidenceView from "../../components/evidence/BlankEvidenceView";
import EvidenceFilterHeader from "../../components/evidence/EvidenceFilterHeader";
import { PMUsageData, ProgramModule } from "pf-support-lib";

const Evidence: React.FC = () => {
  const history = useHistory();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);
  const drafts = useRecoilValueLoadable<IEvidenceDraft[] | null>(evidenceDraftsAtom);
  const appFeatures = useRecoilValue<IAppFeatures[] | string[]>(appFeaturesAtom);

  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  const [draftsHintDismissed, setDraftsHintDismissed] = useRecoilStateLoadable<boolean>(draftsHintDismissedAtom);

  const searchRef = useRef<HTMLInputElement>(null);
  const filenameRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLIonPopoverElement>(null);

  const [sortedEvidence, setSortedEvidence] = useState<IEvidence[]>([]);
  const [hasHCPCEvidence, setHasHCPCEvidence] = useState<boolean>(false);
  const [hasDraftEvidence, setHasDraftEvidence] = useState<boolean>(false);
  const [hasGeneralEvidence, setHasGeneralEvidence] = useState<boolean>(false);
  const [filterHCPC, setFilterHCPC] = useState<boolean>(false);
  const [filterDrafts, setFilterDrafts] = useState<boolean>(false);
  const [filterGeneral, setFilterGeneral] = useState<boolean>(false);
  const [allFilterSelected, setAllFilterSelected] = useState<boolean>(true);

  const [tags, setTags] = useState<string[]>([]);
  const [customTags, setCustomTags] = useState<string[]>([]);
  const [selectedStandards, setSelectedStandards] = useState<string[]>([]);
  const [selectedCustomTags, setSelectedCustomTags] = useState<string[]>([]);
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);

  const [exportingEvidence, setExportingEvidence] = useState<boolean>(false);
  const [defaultFilename, setDefaultFilename] = useState<string>(format(new Date(), "dd-MM-yyyy"));
  const [currentExportType, setCurrentExportType] = useState<string>("");
  const [currentExportProgram, setCurrentExportTypeProgram] = useState<string>("");
  const [filename, setFilename] = useState<string>(defaultFilename);
  const [exportVisible, setExportVisible] = useState<boolean>(false);

  const [searchVisible, setSearchVisible] = useState<boolean>(false);
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchPlaceholder, setSearchPlaceholder] = useState<string>(format(new Date(), "MMM yyyy"));
  const [searchResults, setSearchResults] = useState<IEvidence[]>([]);

  const [popoverFilter, setPopoverFilter] = useState<boolean>(false);

  const [keyboardActive, setKeyboardActive] = useState<boolean>(false);
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  const userPrograms = useRecoilValue<ProgramData[] | any[]>(userProgramsAtom);

  useEffect(() => {
    const windowResized = () => {
      setPopoverVisible(false);
      setFilterVisible(false);

      if (window.innerWidth >= 960) {
        setPopoverFilter(true);
      } else {
        setPopoverFilter(false);
      }
    };

    window.addEventListener("resize", windowResized);

    windowResized();

    return () => {
      window.removeEventListener("resize", windowResized);
    };
  }, []);

  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        setKeyboardActive(true);
        setKeyboardHeight(info.keyboardHeight);
      });

      Keyboard.addListener("keyboardWillHide", () => {
        setKeyboardActive(false);
      });
    }
  }, []);

  useIonViewDidEnter(() => {
    EventRegister.emit("tab-bar/visibility-changed", true);
  });

  useIonViewDidLeave(() => {
    clearSearch();
    clearFilter();
  });

  useEffect(() => {
    const setPageName = async (): Promise<void> => {
      await FirebaseService.setScreenName("evidence");
    };

    setPageName();
  }, []);

  const closeSearch = useCallback((): void => {
    setSearchText("");
    setSearchVisible(false);
  }, []);

  useEffect(() => {
    if (searchVisible) {
      let searchPlaceholders: string[] = [];

      searchPlaceholders = [
        format(new Date(), "MMM yyyy"),
        ...searchResults.map((item) => item.title || ""),
        ...searchResults.map((item) => item.activity?.type || ""),
      ];

      searchPlaceholders = searchPlaceholders.filter((item) => item !== "");

      setSearchPlaceholder(searchPlaceholders[Math.floor(Math.random() * searchPlaceholders.length)]);

      try {
        setTimeout(() => {
          searchRef.current?.focus();
        }, 250);
      } catch (error) {
        console.log(error);
      }
    } else {
      closeSearch();
    }
  }, [closeSearch, searchResults, searchVisible]);

  useEffect(() => {
    const logSearch = async (): Promise<void> => {
      await FirebaseService.logEvent("evidence_search_pressed", {});
    };

    searchVisible && logSearch();
  }, [searchVisible]);

  useEffect(() => {
    EventRegister.addEventListener("evidence/filter-hcpc", () => {
      setFilterHCPC(true);
      setAllFilterSelected(false);
    });

    return () => {
      EventRegister.removeEventListener("evidence/filter-hcpc");
    };
  }, []);

  useEffect(() => {
    const sortEvidenceByDate = (): void => {
      const copy = _.cloneDeep(evidence.contents);

      if (copy) {
        setSortedEvidence(_.orderBy(copy, ["date"], ["desc"]));
        setSearchResults(_.orderBy(copy, ["date"], ["desc"]));
      }
    };

    const getTagsFromEvidence = (): void => {
      const copy = _.cloneDeep(evidence.contents);

      const standards: string[] = copy
        ?.flatMap((item: any) => item.standardTags?.map((tag: any) => tag.tag))
        .filter((item: any) => item);

      const custom: string[] = copy
        ?.flatMap((item: any) => item.customTags?.map((tag: any) => tag.tag))
        .filter((item: any) => item);

      const uniqueTags: string[] = _.uniq(standards).sort();
      const uniqueCustomTags: string[] = _.uniq(custom).sort();

      setTags(uniqueTags);
      setCustomTags(uniqueCustomTags);
    };

    const checkEvidenceHasHCPC = (): void => {
      const copy: IEvidence[] = _.cloneDeep(evidence.contents) || [];

      const array = copy.filter((item) => item.addToHCPCAudit);

      setHasHCPCEvidence(array.length > 0);
    };

    const checkEvidenceHasDrafts = (): void => {
      const copy: IEvidence[] = _.cloneDeep(evidence.contents) || [];

      const array = copy.filter((item) => item.draft === true);

      setHasDraftEvidence(array.length > 0);
    };

    const checkEvidenceHasGeneral = (): void => {
      const copy: IEvidence[] = _.cloneDeep(evidence.contents) || [];

      const array = copy.filter((item) => !item.evidenceJSON);

      setHasGeneralEvidence(array.length > 0);
    };

    evidence.state === "hasValue" && sortEvidenceByDate();
    evidence.state === "hasValue" && getTagsFromEvidence();
    evidence.state === "hasValue" && checkEvidenceHasHCPC();
    evidence.state === "hasValue" && checkEvidenceHasDrafts();
    evidence.state === "hasValue" && checkEvidenceHasGeneral();
  }, [user, evidence, drafts]);

  useEffect(() => {
    if (searchVisible) {
      try {
        setTimeout(() => {
          searchRef.current?.focus();
        }, 250);
      } catch (error) {
        console.log(error);
      }
    } else {
      closeSearch();
    }
  }, [closeSearch, searchVisible]);

  useEffect(() => {
    if (searchText.length > 0 && sortedEvidence && sortedEvidence?.length > 0) {
      const results = EvidenceUtils.searchAndFilterEvidence(
        searchText,
        sortedEvidence,
        selectedStandards,
        selectedCustomTags,
        selectedPrograms,
        filterHCPC,
        filterDrafts,
        filterGeneral
      );

      setSearchResults(results);
    } else {
      const results = EvidenceUtils.filterEvidence(
        sortedEvidence,
        selectedStandards,
        selectedCustomTags,
        selectedPrograms,
        filterHCPC,
        filterDrafts,
        filterGeneral
      );

      setSearchResults(results);
    }
  }, [
    filterGeneral,
    filterHCPC,
    filterDrafts,
    searchText,
    selectedCustomTags,
    selectedPrograms,
    selectedStandards,
    sortedEvidence,
  ]);

  const openFilter = (): void => {
    if (window.innerWidth >= 960) {
      setPopoverVisible(true);
    } else {
      setFilterVisible(true);
    }
  };

  const toggleSearch = (): void => {
    setSearchVisible(!searchVisible);
  };

  const addEvidence = async (): Promise<void> => {
    await FirebaseService.logEvent("add_evidence_pressed", {
      tab: "evidence",
      type: "empty_evidence_button",
    });

    EventRegister.emit("evidence/empty-add-clicked");
  };

  const logDocumentExport = async (pages: number): Promise<void> => {
    await FirebaseService.logEvent("evidence_exported", { pages });
  };

  const generatePdfDocument = useCallback(
    async (exportType: string, program?: ProgramData): Promise<void> => {
      try {
        const doc = pdf();

        let evidenceToExport: IEvidence[] = [];
        let usageData: UsageData[] | undefined;

        if (exportType === IExportType.MAJOR_INCIDENT) {
          // Everything tagged with NOS standards - include table
          let copy = _.cloneDeep(sortedEvidence);
          copy = copy.filter((item) => item.standardTags && item.standardTags?.length > 0);

          evidenceToExport = _.sortBy(copy, (item) => new Date(item.date).getTime()).reverse();
        } else if (exportType === IExportType.PROGRAM) {
          // All evidence for the specific program selected - e.g. HART, CHUB, etc.
          let copy = _.cloneDeep(sortedEvidence);
          copy = copy.filter((item) => {
            const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
            const programName = evidenceJSON?.programInfo?.program || "";

            return programName === program?.Name;
          });

          evidenceToExport = _.sortBy(copy, (item) => new Date(item.date).getTime()).reverse();
        } else if (exportType === IExportType.HCPC) {
          // Everything tagged as HCPC - include personal statement and summary of work if exists
          let copy = _.cloneDeep(sortedEvidence);
          copy = copy.filter((item) => item.addToHCPCAudit);

          evidenceToExport = _.sortBy(copy, (item) => new Date(item.date).getTime()).reverse();
        } else if (exportType === IExportType.GENERAL) {
          // Everything currently visible on Evidence tab - don't include table or statements
          evidenceToExport = _.sortBy(_.cloneDeep(searchResults), (item) => new Date(item.date).getTime()).reverse();
        }

        evidenceToExport = evidenceToExport.filter((item: IEvidence) => {
          const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
          const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

          return onHoldReset !== 1 && item.draft !== true;
        });

        if (program && ProgramUtils.shouldCalculateUsageData(program.ID)) {
          const pm = new ProgramModule(program);

          const contactID = Number(user.contents?.userData.contactID);
          const roleID = ProgramUtils.getSelectedRoleIdForNWASICProgram(user.contents);

          const pMUsageData = new PMUsageData(pm, []);

          usageData = pMUsageData.getUsageDataByUser(pm, contactID, roleID, evidenceToExport);
        }

        let programProgressCheckData: AllProgressCheckStatuses | null = null;
        if (allProgressCheckData.state === "hasValue" && program) {
          const dataForProgram = allProgressCheckData.contents?.find(
            (item: any): item is AllProgressCheckStatuses => item.programID === program.ID
          );

          if (dataForProgram) {
            programProgressCheckData = dataForProgram;
          }
        }

        doc.updateContainer(
          <ExportedPDF
            evidence={evidenceToExport}
            user={user.contents}
            isHCPC={exportType === IExportType.HCPC}
            summaryOfWork={
              exportType === IExportType.HCPC && user.state === "hasValue" ? user.contents?.summaryOfWork : undefined
            }
            personalStatement={
              exportType === IExportType.HCPC && user.state === "hasValue"
                ? user.contents?.personalStatement
                : undefined
            }
            isMajorIncident={exportType === IExportType.MAJOR_INCIDENT}
            program={program}
            userPrograms={userPrograms}
            usageData={usageData}
            programProgressCheckData={programProgressCheckData}
          />
        );

        doc.on("change", async () => {
          if (doc.container.document && doc.container.document.children.length > 0) {
            const blob = await doc.toBlob();

            try {
              await logDocumentExport(doc.container.document.children.length);
            } catch (error) {
              console.log(error);
            }

            if (await DataController.isWebAsync()) {
              saveAs(blob, filename.trim().length > 0 ? filename.trim() : "Untitled");
            } else {
              const filePath = await WriteBlob({
                path: `ParaFolio/Exports/${DataController.getFolderFromExportType(exportType)}/${filename.trim().length > 0 ? filename.trim() : "Untitled"}.pdf`,
                blob,
                directory: Directory.Library,
                recursive: true,
              });

              FileOpener.open({
                filePath,
              });
            }

            doc.updateContainer(<Document />);
            setExportingEvidence(false);
            setCurrentExportType("");
            setCurrentExportTypeProgram("");
          }
        });
      } catch (error) {
        console.log(error);
        setExportingEvidence(false);
        setCurrentExportType("");
        setCurrentExportTypeProgram("");
      }
    },
    [filename, searchResults, sortedEvidence, user]
  );

  const exportEvidence = useCallback(
    async (exportType: string, program?: ProgramData): Promise<void> => {
      try {
        if (user.state === "hasValue" && user.contents) {
          setExportingEvidence(true);
          setCurrentExportType(exportType);
          program && setCurrentExportTypeProgram(program.Name);
          await generatePdfDocument(exportType, program);
        }
      } catch (error) {
        console.log(error);
        window.alert("An error occurred while exporting, please try again.");
        setExportingEvidence(false);
        setCurrentExportType("");
        setCurrentExportTypeProgram("");
      }
    },
    [generatePdfDocument, user.contents, user.state]
  );

  const searchTextChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const searchFieldButtonClicked = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Escape") {
      closeSearch();
    }
  };

  const searchFocused = (): void => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const clearSearch = (focus?: boolean): void => {
    setSearchText("");
    focus && searchRef.current?.focus();
  };

  const evidenceCardClicked = (item: IEvidence): void => {
    const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);

    if (evidenceJSON && ProgramUtils.isWellbeingActivity(evidenceJSON.programInfo.progressCheckCompetenceID)) {
      history.push(
        `/dashboard/program/${evidenceJSON.programInfo.programID}/progress-check/${evidenceJSON.programInfo.progressCheckID}/wellbeing-activity/${evidenceJSON.programInfo.progressCheckCompetenceID}/results/${item.id}`
      );
    } else {
      let _evidence = _.pickBy(_.cloneDeep(item), _.identity);

      history.push(`/evidence/read/${_evidence.id}`);
    }
  };

  const toggleStandardFilter = (value: string): void => {
    let array = _.cloneDeep(selectedStandards);

    if (array.includes(value)) {
      array = array.filter((item) => item !== value);
    } else {
      array.push(value);
    }

    setSelectedStandards(array);
    setAllFilterSelected(false);
  };

  const toggleCustomFilter = (value: string): void => {
    let array = _.cloneDeep(selectedCustomTags);

    if (array.includes(value)) {
      array = array.filter((item) => item !== value);
    } else {
      array.push(value);
    }

    setSelectedCustomTags(array);
    setAllFilterSelected(false);
  };

  const toggleProgramFilter = (value: string): void => {
    let array = _.cloneDeep(selectedPrograms);

    if (array.includes(value)) {
      array = array.filter((item) => item !== value);
    } else {
      array.push(value);
    }

    setSelectedPrograms(array);
    setAllFilterSelected(false);
  };

  const clearFilter = (): void => {
    setSelectedStandards([]);
    setSelectedCustomTags([]);
    setSelectedPrograms([]);
    setFilterHCPC(false);
    setFilterDrafts(false);
    setFilterGeneral(false);
    setAllFilterSelected(true);
  };

  useEffect(() => {
    if (
      !allFilterSelected &&
      !filterGeneral &&
      !filterHCPC &&
      !filterDrafts &&
      selectedCustomTags.length === 0 &&
      selectedStandards.length === 0 &&
      selectedPrograms.length === 0
    ) {
      clearFilter();
    }
  }, [
    allFilterSelected,
    customTags,
    evidence.contents,
    filterHCPC,
    filterDrafts,
    searchResults,
    selectedCustomTags,
    selectedStandards,
    selectedPrograms,
    tags,
  ]);

  const closeModal = async (): Promise<void> => {
    try {
      if (selectedStandards.length > 0 || selectedCustomTags.length > 0) {
        await FirebaseService.logEvent("filtered_evidence", {
          ...(selectedStandards.length > 0 && {
            selected_standards: JSON.stringify(selectedStandards),
          }),
          ...(selectedCustomTags.length > 0 && {
            selected_custom: selectedCustomTags.length,
          }),
          ...(selectedPrograms.length > 0 && {
            selected_programs: JSON.stringify(selectedPrograms),
          }),
        });
      }
    } catch (error) {
      console.log(error);
    }

    setFilterVisible(false);
    setPopoverVisible(false);
  };

  const filenameChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilename(event.target.value);
  };

  const openExportModal = (): void => {
    setDefaultFilename(
      `${user.contents.userData.userName?.replace(/\s/gi, "-") || "Untitled"}-Portfolio-${format(new Date(), "dd-MM-yyyy")}`
    );
    setFilename(
      `${user.contents.userData.userName?.replace(/\s/gi, "-") || "Untitled"}-Portfolio-${format(new Date(), "dd-MM-yyyy")}`
    );
    setExportVisible(true);
  };

  const closeEvidenceDraftsHint = async (): Promise<void> => {
    try {
      await Preferences.set({ key: "draftsHintDismissed", value: "true" });
      setDraftsHintDismissed(true);
    } catch (error) {
      console.log(error);
    }
  };

  const closeExportModal = (): void => {
    setExportVisible(false);
  };

  const toggleFilterAll = useCallback((): void => {
    if (!allFilterSelected) {
      clearFilter();
    }
  }, [allFilterSelected]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonMenuButton>
              <IonImg src={Button_Menu} className="menuIcon" />
            </IonMenuButton>
          </IonButtons>
          <HeaderTitle>{"Evidence"}</HeaderTitle>
          {EvidenceUtils.hasData(evidence.contents) && (
            <IonButtons slot="end">
              <IonButton id="evidenceFilterButton" className="header-button" mode="ios" onClick={() => openFilter()}>
                <IonImg
                  src={
                    selectedPrograms.length === 0 &&
                    selectedStandards.length === 0 &&
                    selectedCustomTags.length === 0 &&
                    !filterHCPC
                      ? Button_Filter
                      : Button_Filter_Active
                  }
                  className="headerIcon"
                />
              </IonButton>
              <IonButton className="header-button" mode="ios" onClick={() => toggleSearch()}>
                <IonImg src={searchVisible ? Button_Search_Active : Button_Search} className="headerIcon" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
        <IonModal
          id={!appFeatures.includes(IAppFeatures.SWAST_MIC) ? "exportModalSmall" : "exportModal"}
          isOpen={exportVisible}
          onDidDismiss={() => closeExportModal()}
          canDismiss={true}
          style={{
            "--height": 400,
          }}
          className="fullscreen"
        >
          <IonHeader>
            <IonToolbar mode="ios" className="navBar" style={{ maxWidth: DataController.isWeb() ? 980 : undefined }}>
              <IonTitle>{"Export"}</IonTitle>
              <IonButtons slot="end">
                <IonButton mode="ios" className="cancelButton" onClick={() => closeExportModal()}>
                  {"Done"}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <div className="inner-content">
            <div className="exportModalContainer">
              <div>
                <div className="exportModalTitle">{"Set a file name"}</div>
                <input
                  ref={filenameRef}
                  className="exportFilenameInput"
                  placeholder="Enter a filename..."
                  autoComplete="off"
                  autoCapitalize="words"
                  id="reflectionTitleInput"
                  value={filename}
                  onChange={filenameChanged}
                />
              </div>
              <div>
                <IonButton
                  disabled={searchResults?.length === 0 || exportingEvidence}
                  onClick={() => exportEvidence(IExportType.GENERAL)}
                  className="generateButton"
                >
                  {exportingEvidence && currentExportType === IExportType.GENERAL ? (
                    <IonSpinner className="exportingEvidenceSpinner" />
                  ) : (
                    <div>{"Export all Evidence"}</div>
                  )}
                </IonButton>
                <IonButton
                  disabled={exportingEvidence}
                  onClick={() => exportEvidence(IExportType.HCPC)}
                  className="generateButton"
                >
                  {exportingEvidence && currentExportType === IExportType.HCPC ? (
                    <IonSpinner className="exportingEvidenceSpinner" />
                  ) : (
                    <div>{"Export Audit Profile"}</div>
                  )}
                </IonButton>
                {appFeatures.includes(IAppFeatures.SWAST_MIC) && (
                  <IonButton
                    disabled={exportingEvidence}
                    onClick={() => exportEvidence(IExportType.MAJOR_INCIDENT)}
                    className="generateButton"
                  >
                    {exportingEvidence && currentExportType === IExportType.MAJOR_INCIDENT ? (
                      <IonSpinner className="exportingEvidenceSpinner" />
                    ) : (
                      <div>{"Export Incident Portfolio"}</div>
                    )}
                  </IonButton>
                )}
                {userPrograms.map((program: ProgramData, index) => {
                  let programName = program.Name;

                  return (
                    <IonButton
                      key={program.Title}
                      disabled={exportingEvidence}
                      onClick={() => exportEvidence(IExportType.PROGRAM, program)}
                      className="generateButton"
                    >
                      {exportingEvidence &&
                      currentExportType === IExportType.PROGRAM &&
                      currentExportProgram === programName ? (
                        <IonSpinner className="exportingEvidenceSpinner" />
                      ) : (
                        <div>{`Export ${program.Title}`}</div>
                      )}
                    </IonButton>
                  );
                })}
              </div>
            </div>
          </div>
        </IonModal>
        {!popoverFilter && (
          <IonModal
            id="evidenceFilterModal"
            isOpen={filterVisible}
            onWillDismiss={() => closeModal()}
            initialBreakpoint={0.7}
            breakpoints={[0, 0.7, 1]}
            onDidDismiss={() => setFilterVisible(false)}
            canDismiss={true}
            className="fullscreen"
          >
            <IonHeader>
              <IonToolbar mode="ios" className="navBar !pb-0">
                <IonButtons slot="start">
                  <IonButton
                    mode="ios"
                    className="cancelButton"
                    onClick={() => clearFilter()}
                    disabled={
                      selectedPrograms.length === 0 &&
                      selectedStandards.length === 0 &&
                      selectedCustomTags.length === 0 &&
                      !filterHCPC &&
                      !filterDrafts
                    }
                  >
                    {"Reset"}
                  </IonButton>
                </IonButtons>
                <IonTitle>{"Filter"}</IonTitle>
                <IonButtons slot="end">
                  <IonButton mode="ios" className="cancelButton" onClick={() => closeModal()}>
                    {"Done"}
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="filtersModal" fullscreen={true}>
              <div>
                <IonItem
                  button
                  detail={false}
                  className="timelineFilterCheckboxContainer"
                  onClick={() => toggleFilterAll()}
                >
                  <div className="timelineCheckboxContainerInner">
                    <div className="timelineCheckboxLabel">{"All"}</div>
                    <img
                      alt=""
                      className="timelineRadioCheckbox"
                      src={allFilterSelected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                    />
                  </div>
                </IonItem>
              </div>
              {hasGeneralEvidence && (
                <div>
                  <IonItem
                    button
                    detail={false}
                    className="timelineFilterCheckboxContainer"
                    onClick={() => {
                      setFilterGeneral(!filterGeneral);
                      setAllFilterSelected(false);
                    }}
                  >
                    <div className="timelineCheckboxContainerInner">
                      <div className="timelineCheckboxLabel">{"General"}</div>
                      <img
                        alt=""
                        className="timelineRadioCheckbox"
                        src={
                          allFilterSelected
                            ? Button_Tickbox_3_Inactive
                            : filterGeneral
                              ? Button_Checkbox_Active
                              : Button_Checkbox_Inactive
                        }
                      />
                    </div>
                  </IonItem>
                </div>
              )}
              {hasDraftEvidence && (
                <div>
                  <IonItem
                    button
                    detail={false}
                    className="filtersCheckboxContainer"
                    onClick={() => {
                      setFilterDrafts(!filterDrafts);
                      setAllFilterSelected(false);
                    }}
                  >
                    <div className="filtersCheckboxContainerInner">
                      <div className="filtersCheckboxLabel">{"Drafts"}</div>
                      <img
                        alt=""
                        className="filtersRadioCheckbox"
                        src={
                          allFilterSelected
                            ? Button_Tickbox_3_Inactive
                            : filterDrafts
                              ? Button_Checkbox_Active
                              : Button_Checkbox_Inactive
                        }
                      />
                    </div>
                  </IonItem>
                </div>
              )}
              {hasHCPCEvidence && (
                <div>
                  <IonItem
                    button
                    detail={false}
                    className="filtersCheckboxContainer"
                    onClick={() => {
                      setFilterHCPC(!filterHCPC);
                      setAllFilterSelected(false);
                    }}
                  >
                    <div className="filtersCheckboxContainerInner">
                      <div className="filtersCheckboxLabel">{"Audit tagged"}</div>
                      <img
                        alt=""
                        className="filtersRadioCheckbox"
                        src={
                          allFilterSelected
                            ? Button_Tickbox_3_Inactive
                            : filterHCPC
                              ? Button_Checkbox_Active
                              : Button_Checkbox_Inactive
                        }
                      />
                    </div>
                  </IonItem>
                </div>
              )}
              {userPrograms.map((program: ProgramData, index) => {
                const selected = selectedPrograms.includes(program.Name);

                return (
                  <div key={program.Name}>
                    {index === 0 && <EvidenceFilterHeader title="Program Modules" />}
                    <IonItem
                      button
                      detail={false}
                      className="filtersCheckboxContainer"
                      onClick={() => toggleProgramFilter(program.Name)}
                    >
                      <div className="filtersCheckboxContainerInner">
                        <div className="filtersCheckboxLabel">{program.Name}</div>
                        <img
                          alt=""
                          className="filtersRadioCheckbox"
                          src={
                            allFilterSelected
                              ? Button_Tickbox_3_Inactive
                              : selected
                                ? Button_Checkbox_Active
                                : Button_Checkbox_Inactive
                          }
                        />
                      </div>
                    </IonItem>
                  </div>
                );
              })}
              {tags && tags.length > 0 && (
                <div>
                  {tags.map((item, index) => {
                    const selected = selectedStandards.includes(item);

                    return (
                      <div key={item}>
                        {index === 0 && <EvidenceFilterHeader title="NOS" />}
                        <IonItem
                          button
                          detail={false}
                          className="filtersCheckboxContainer"
                          onClick={() => toggleStandardFilter(item)}
                        >
                          <div className="filtersCheckboxContainerInner">
                            <div className="filtersCheckboxLabel">{item}</div>
                            <img
                              alt=""
                              className="filtersRadioCheckbox"
                              src={
                                allFilterSelected
                                  ? Button_Tickbox_3_Inactive
                                  : selected
                                    ? Button_Checkbox_Active
                                    : Button_Checkbox_Inactive
                              }
                            />
                          </div>
                        </IonItem>
                      </div>
                    );
                  })}
                </div>
              )}
              {customTags && customTags.length > 0 && (
                <div>
                  {customTags.map((item, index) => {
                    const selected = selectedCustomTags.includes(item);

                    return (
                      <div key={item}>
                        {index === 0 && <EvidenceFilterHeader title="Custom Tags" />}
                        <IonItem
                          button
                          detail={false}
                          className="filtersCheckboxContainer"
                          onClick={() => toggleCustomFilter(item)}
                        >
                          <div className="filtersCheckboxContainerInner">
                            <div className="filtersCheckboxLabel">{item}</div>
                            <img
                              alt=""
                              className="filtersRadioCheckbox"
                              src={
                                allFilterSelected
                                  ? Button_Tickbox_3_Inactive
                                  : selected
                                    ? Button_Checkbox_Active
                                    : Button_Checkbox_Inactive
                              }
                            />
                          </div>
                        </IonItem>
                      </div>
                    );
                  })}
                </div>
              )}
            </IonContent>
          </IonModal>
        )}
        {popoverFilter && (
          <IonPopover
            id="evidenceFilterPopover"
            className="evidenceFilterPopover"
            ref={popoverRef}
            trigger={`${window.innerWidth >= 960 ? "evidenceFilterButton" : undefined}`}
            mode="ios"
            isOpen={popoverVisible}
            onDidDismiss={() => setPopoverVisible(false)}
            animated={true}
            reference="trigger"
            side="bottom"
            backdropDismiss={true}
          >
            <div>
              <div slot="fixed">
                <IonHeader className="evidencePopoverHeader" role="banner">
                  <IonToolbar className="navBar filterNavBar">
                    <IonButtons slot="start">
                      <IonButton
                        mode="ios"
                        className="cancelButton"
                        onClick={() => clearFilter()}
                        disabled={
                          selectedPrograms.length === 0 &&
                          selectedStandards.length === 0 &&
                          selectedCustomTags.length === 0 &&
                          !filterHCPC &&
                          !filterDrafts
                        }
                      >
                        {"Reset"}
                      </IonButton>
                    </IonButtons>
                    <IonTitle>{"Filter"}</IonTitle>
                    <IonButtons slot="end">
                      <IonButton mode="ios" className="cancelButton" onClick={() => closeModal()}>
                        {"Done"}
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
              </div>
              <div className="filtersModal">
                <div>
                  <IonItem
                    button
                    detail={false}
                    className="timelineFilterCheckboxContainer"
                    onClick={() => toggleFilterAll()}
                  >
                    <div className="timelineCheckboxContainerInner">
                      <div className="timelineCheckboxLabel">{"All"}</div>
                      <img
                        alt=""
                        className="timelineRadioCheckbox"
                        src={allFilterSelected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                      />
                    </div>
                  </IonItem>
                </div>
                {hasGeneralEvidence && (
                  <div>
                    <IonItem
                      button
                      detail={false}
                      className="timelineFilterCheckboxContainer"
                      onClick={() => {
                        setFilterGeneral(!filterGeneral);
                        setAllFilterSelected(false);
                      }}
                    >
                      <div className="timelineCheckboxContainerInner">
                        <div className="timelineCheckboxLabel">{"General"}</div>
                        <img
                          alt=""
                          className="timelineRadioCheckbox"
                          src={
                            allFilterSelected
                              ? Button_Tickbox_3_Inactive
                              : filterGeneral
                                ? Button_Checkbox_Active
                                : Button_Checkbox_Inactive
                          }
                        />
                      </div>
                    </IonItem>
                  </div>
                )}
                {hasDraftEvidence && (
                  <div>
                    <IonItem
                      button
                      detail={false}
                      className="filtersCheckboxContainer"
                      onClick={() => {
                        setFilterDrafts(!filterDrafts);
                        setAllFilterSelected(false);
                      }}
                    >
                      <div className="filtersCheckboxContainerInner">
                        <div className="filtersCheckboxLabel">{"Drafts"}</div>
                        <img
                          alt=""
                          className="filtersRadioCheckbox"
                          src={
                            allFilterSelected
                              ? Button_Tickbox_3_Inactive
                              : filterDrafts
                                ? Button_Checkbox_Active
                                : Button_Checkbox_Inactive
                          }
                        />
                      </div>
                    </IonItem>
                  </div>
                )}
                {hasHCPCEvidence && (
                  <div>
                    <IonItem
                      button
                      detail={false}
                      className="filtersCheckboxContainer"
                      onClick={() => {
                        setFilterHCPC(!filterHCPC);
                        setAllFilterSelected(false);
                      }}
                    >
                      <div className="filtersCheckboxContainerInner">
                        <div className="filtersCheckboxLabel">{"Audit tagged"}</div>
                        <img
                          alt=""
                          className="filtersRadioCheckbox"
                          src={
                            allFilterSelected
                              ? Button_Tickbox_3_Inactive
                              : filterHCPC
                                ? Button_Checkbox_Active
                                : Button_Checkbox_Inactive
                          }
                        />
                      </div>
                    </IonItem>
                  </div>
                )}
                {userPrograms.map((program: ProgramData, index) => {
                  const selected = selectedPrograms.includes(program.Name);

                  return (
                    <div key={program.Name}>
                      {index === 0 && <EvidenceFilterHeader title="Program Modules" />}
                      <IonItem
                        button
                        detail={false}
                        className="filtersCheckboxContainer"
                        onClick={() => toggleProgramFilter(program.Name)}
                      >
                        <div className="filtersCheckboxContainerInner">
                          <div className="filtersCheckboxLabel">{program.Name}</div>
                          <img
                            alt=""
                            className="filtersRadioCheckbox"
                            src={
                              allFilterSelected
                                ? Button_Tickbox_3_Inactive
                                : selected
                                  ? Button_Checkbox_Active
                                  : Button_Checkbox_Inactive
                            }
                          />
                        </div>
                      </IonItem>
                    </div>
                  );
                })}
                {tags && tags.length > 0 && (
                  <div>
                    {tags.map((item, index) => {
                      const selected = selectedStandards.includes(item);

                      return (
                        <div key={item}>
                          {index === 0 && <EvidenceFilterHeader title="NOS" />}
                          <IonItem
                            button
                            detail={false}
                            key={item}
                            className="filtersCheckboxContainer"
                            onClick={() => toggleStandardFilter(item)}
                          >
                            <div className="filtersCheckboxContainerInner">
                              <div className="filtersCheckboxLabel">{item}</div>
                              <img
                                alt=""
                                className="filtersRadioCheckbox"
                                src={
                                  allFilterSelected
                                    ? Button_Tickbox_3_Inactive
                                    : selected
                                      ? Button_Checkbox_Active
                                      : Button_Checkbox_Inactive
                                }
                              />
                            </div>
                          </IonItem>
                        </div>
                      );
                    })}
                  </div>
                )}
                {customTags && customTags.length > 0 && (
                  <div>
                    {customTags.map((item, index) => {
                      const selected = selectedCustomTags.includes(item);

                      return (
                        <div key={item}>
                          {index === 0 && <EvidenceFilterHeader title="Custom Tags" />}
                          <IonItem
                            button
                            detail={false}
                            className="filtersCheckboxContainer"
                            onClick={() => toggleCustomFilter(item)}
                          >
                            <div className="filtersCheckboxContainerInner">
                              <div className="filtersCheckboxLabel">{item}</div>
                              <img
                                alt=""
                                className="filtersRadioCheckbox"
                                src={
                                  allFilterSelected
                                    ? Button_Tickbox_3_Inactive
                                    : selected
                                      ? Button_Checkbox_Active
                                      : Button_Checkbox_Inactive
                                }
                              />
                            </div>
                          </IonItem>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </IonPopover>
        )}
        {EvidenceUtils.hasData(evidence.contents) && searchVisible && (
          <div className="evidenceSearchHeader">
            <div className="evidenceSearchInputContainer">
              <img alt="" src={Icon_Search_Small} className="evidenceSearchIcon" />
              <input
                value={searchText}
                className="searchInputField"
                placeholder={`Try searching “${searchPlaceholder}”`}
                onChange={searchTextChanged}
                ref={searchRef}
                onKeyDown={searchFieldButtonClicked}
                onFocus={() => searchFocused()}
              />
              {searchText.length > 0 && (
                <IonButton className="searchClearButton" mode="ios" onClick={() => clearSearch(true)}>
                  <img alt="" src={Button_Cancel} className="icon-44 mt-[-5px]" />
                </IonButton>
              )}
            </div>
            {searchText.length > 0 && (
              <div className="evidenceResultsText">{EvidenceUtils.getSearchResultText(searchResults)}</div>
            )}
          </div>
        )}
      </IonHeader>
      <IonContent className="page-background">
        <EvidenceContainer
          id="evidenceContentContainer"
          style={{
            paddingTop: 0,
            paddingBottom: keyboardActive ? keyboardHeight : EvidenceUtils.hasData(evidence.contents) ? "96px" : "40px",
          }}
        >
          {evidence.state === "hasValue" && evidence.contents && evidence.contents?.length > 0 ? (
            <>
              {draftsHintDismissed.state === "hasValue" && !draftsHintDismissed.contents && (
                <EvidenceDraftsHint buttonPressed={closeEvidenceDraftsHint} />
              )}
              <ExportButtonContainer>
                <ExportButton disabled={evidence.contents?.length === 0} onClick={openExportModal}>
                  {"Export evidence"}
                </ExportButton>
              </ExportButtonContainer>
              {searchResults &&
                searchResults?.map((item, index) => (
                  <EvidenceSearchCard
                    key={item.id}
                    buttonPressed={() => evidenceCardClicked(item)}
                    evidence={item}
                    searchText={searchText}
                    showHeader={
                      index === 0 ||
                      new Date(searchResults[index - 1].date).getMonth() !== new Date(item.date).getMonth()
                    }
                  />
                ))}
            </>
          ) : (
            <BlankEvidenceView addEvidence={() => addEvidence()} />
          )}
        </EvidenceContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Evidence);
