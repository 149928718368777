import { FileOpener } from "@capacitor-community/file-opener";
import { Capacitor } from "@capacitor/core";
import { Directory } from "@capacitor/filesystem";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { Document, pdf } from "@react-pdf/renderer";
import WriteBlob from "capacitor-blob-writer";
import { format, subYears } from "date-fns";
import saveAs from "file-saver";
import _ from "lodash";
import mime from "mime";
import { ConfidenceLevelGraph, DetailsCard, ProgressCheckButton } from "parafolio-components";
import { PMUsageData, ProgramModule } from "pf-support-lib";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EventRegister } from "react-native-event-listeners";
import { useHistory, useParams } from "react-router";
import { useRecoilStateLoadable, useRecoilValueLoadable } from "recoil";
import {
  Button_Info_White,
  Icon_Add_Evidence,
  Icon_Export_Blue,
  Icon_Pencil,
  Icon_Refresh,
  Image_Completion,
} from "../../assets/images";
import CustomProgressCheckButton from "../../components/common/CustomProgressCheckButton";
import HeaderTitle from "../../components/common/HeaderTitle";
import EvidenceContainer from "../../components/evidence/EvidenceContainer";
import ProgramInfoCard from "../../components/home/ProgramInfoCard";
import ExportedPDF from "../../components/pdfExport/ExportedPDF";
import CompetenceItem from "../../components/programs/CompetenceItem";
import DetailsContentCollapsible from "../../components/programs/DetailsContentCollapsible";
import InfoModal from "../../components/programs/InfoModal";
import MandatoryCertificateDisplay from "../../components/programs/MandatoryCertificateDisplay";
import NOSOverviewItem from "../../components/programs/NOSOverviewItem";
import ProgramCardButton from "../../components/programs/ProgramCardButton";
import ProgramCertificatesDisplay from "../../components/programs/ProgramCertificatesDisplay";
import ProgramEvidenceCard from "../../components/programs/ProgramEvidenceCard";
import ProgramRolesDisplay from "../../components/programs/ProgramRolesDisplay";
import RequiredSkillCard from "../../components/programs/RequiredSkillCard";
import AuthenticationController from "../../controllers/AuthenticationController";
import AWSService from "../../controllers/AWSService";
import DataController from "../../controllers/DataController";
import { FirebaseService } from "../../controllers/FirebaseService";
import { useDebugUser } from "../../hooks/auth/useDebugUser";
import { useProgramData } from "../../hooks/data/useProgramData";
import { useProgramEvidence } from "../../hooks/programs/useProgramEvidence";
import { useProgramInfo } from "../../hooks/programs/useProgramInfo";
import { useProgramSkills } from "../../hooks/programs/useProgramSkills";
import { useProgressCheckData } from "../../hooks/programs/useProgressCheckData";
import {
  AWSAccessParams,
  AllProgressCheckStatuses,
  CompetenceActivityClass,
  ConfidenceLevelData,
  IClassSubscription,
  IEvidence,
  IExportType,
  IUser,
  IUserProgramCertificate,
  ProgramCertificate,
  ProgramData,
  ProgramSkill,
  ProgressCheck,
  Required,
  UsageData,
} from "../../Interfaces";
import { evidenceAtom, progressCheckDataAtom, userAtom } from "../../state/State";
import { CommentHelpers } from "../../utils/commentHelpers";
import * as ProgramUtils from "../../utils/programUtils";
import { StringUtils } from "../../utils/stringUtils";
import LasDetails from "./LasDetails";
import MonthlyEvidenceBreakdown from "../../components/evidence/MonthlyEvidenceBreakdown";

const ProgramHome: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ programId: string }>();

  const { userPrograms } = useProgramData();
  const { isDebugUser } = useDebugUser();
  const program: ProgramData = userPrograms.find((item) => item.ID === params.programId)!;

  const currentProgram = useProgramInfo(params.programId);
  const programSkills = useProgramSkills(params.programId);
  const programEvidence = useProgramEvidence(currentProgram.program);
  const { programProgressCheckData } = useProgressCheckData(params.programId);

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[] | null>(progressCheckDataAtom);

  const [requiredSkills, setRequiredSkills] = useState<ProgramSkill[] | any[]>([]);
  const [certificates, setCertificates] = useState<ProgramCertificate[] | any[]>([]);
  const [currentProgressCheckData, setCurrentProgressCheckData] = useState<AllProgressCheckStatuses | null>(null);

  const [infoModalVisible, setInfoModalVisible] = useState<boolean>(false);
  const [infoBody, setInfoBody] = useState<string | null>(null);

  const [isSetup, setIsSetup] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<string[]>([]);

  const [exportingEvidence, setExportingEvidence] = useState<boolean>(false);
  const [currentExportType, setCurrentExportType] = useState<string>("");

  const [programCertificates, setProgramCertificates] = useState<IUserProgramCertificate[] | any[]>([]);

  const [viewingCertificate, setViewingCertificate] = useState<boolean>(false);
  const [viewingCertificateFilename, setViewingCertificateFilename] = useState<string>("");

  const [refreshingData, setRefreshingData] = useState<boolean>(false);

  const [usageData, setUsageData] = useState<UsageData[]>([]);

  const confidenceLevelData: ConfidenceLevelData | null = useMemo(() => {
    const confidenceLevelEvidence = ProgramUtils.getConfidenceLevelEvidence(programEvidence);
    let mappedData = ProgramUtils.mapConfidenceLevelEvidence(confidenceLevelEvidence);

    return mappedData;
  }, [JSON.stringify(programEvidence)]);

  const infoClicked = useCallback((): void => {
    FirebaseService.logEvent("program_info_pressed", {
      program: currentProgram.program?.Name,
    });

    setInfoModalVisible(true);
  }, [currentProgram.program]);

  useEffect(() => {
    EventRegister.addEventListener("program/data-refreshed", () => setRefreshingData(false));

    return () => {
      EventRegister.removeEventListener("program/data-refreshed");
    };
  }, []);

  useEffect(() => {
    const getInfoDismissed = async (): Promise<void> => {
      const subscriptions = user.contents?.userData.subscriptions || [];
      const programSubscriptionIDs = program?.Subscription || [];

      const programSubscription: IClassSubscription = subscriptions.find((item: IClassSubscription | any) => {
        const subscriptionPrograms: string[] = item.ParaFolioPrograms;

        return (
          subscriptionPrograms?.some((_sub) => program.Subscription?.includes(_sub)) ||
          programSubscriptionIDs.includes(`${item.SubscriptionID}`)
        );
      });

      if (programSubscription && programSubscription.App_Data) {
        let json: any = {};

        try {
          json = JSON.parse(programSubscription.App_Data);
        } catch (error) {
          console.log(error);
        }
      }
    };

    if (user.state === "hasValue" && program) {
      getInfoDismissed();
    }
  }, [program?.version, user]);

  useEffect(() => {
    const getProgram = (): void => {
      if (program) {
        const info = program.PopupDisclaimer;

        if (info) {
          setInfoBody(info);
        }

        const allCompetences: CompetenceActivityClass[] | any[] = program.CompetenceActivity ?? [];

        if (allCompetences.length > 0) {
          const required = ProgramUtils.getAllRequiredSkillsForProgram(allCompetences);

          if (required) {
            setRequiredSkills(required);
          }
        }
      }
    };

    getProgram();
  }, [evidence, program?.version]);

  useEffect(() => {
    const getProgressCheckData = (): void => {
      const dataForProgram: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === params.programId
      );

      dataForProgram && setCurrentProgressCheckData(dataForProgram);
    };

    if (allProgressCheckData.state === "hasValue") {
      getProgressCheckData();
    }
  }, [allProgressCheckData, currentProgram.program, currentProgram.progressChecks]);

  useEffect(() => {
    const programOrder = (item: IUserProgramCertificate): number => {
      const certificateName = item?.certificate?.certificateType;
      const mappedCertificate = currentProgram.program?.Certificates?.find(
        (_cert: ProgramCertificate) => _cert.Name.toUpperCase() === certificateName?.toUpperCase()
      );

      return mappedCertificate?.Required === Required.Mandatory ? 2 : 1;
    };

    const getCertificateInfo = (): void => {
      if (program) {
        const competences = program.CompetenceActivity ?? [];

        const filteredCompetences = competences.filter(
          (item) => item && item.Certificates && item.Certificates.length > 0
        );

        const mappedCompetences = filteredCompetences.map((item: CompetenceActivityClass) => item && item.Certificates);
        const array = mappedCompetences.filter((item) => item !== undefined);
        let _certificates = _.flatten(array);

        _certificates = _certificates.slice(0, 3);
        setCertificates(_certificates);
      }
    };

    const getUserProgramCertificates = (): void => {
      if (user.contents) {
        let _programCertificates = user.contents?.programCertificates || [];
        _programCertificates = _programCertificates.filter(
          (item: IUserProgramCertificate) => item.programData.programName === currentProgram.program?.Name
        );

        setProgramCertificates(
          _.orderBy(_programCertificates, [programOrder, (item) => item.certificate.certificateType], ["desc", "asc"])
        );
      }
    };

    if (user.state === "hasValue" && currentProgram.program) {
      getCertificateInfo();
      getUserProgramCertificates();
    }
  }, [currentProgram.program, user]);

  useEffect(() => {
    const checkIsSetup = (): void => {
      const roles = user.state === "hasValue" && _.cloneDeep(user.contents?.programRoles);

      if (roles) {
        const programRoles = roles.find((item) => item.programName === currentProgram.program.Name);

        if (programRoles) {
          if (ProgramUtils.usingDescriptiveProgramRolesFormat(params.programId)) {
            const roles = programRoles.programRoles.map((r) => r.roleName);
            setUserRoles(roles);
          } else {
            setUserRoles(programRoles.programRoles);
          }

          setIsSetup(true);
        }
      }
    };

    if (user.state === "hasValue" && user.contents && currentProgram.program) {
      checkIsSetup();
    }
  }, [user, currentProgram.program]);

  useEffect(() => {
    const calculateUsageData = () => {
      if (!currentProgram.program || !ProgramUtils.shouldCalculateUsageData(params.programId)) return;

      const eprr: any = userPrograms.find((program) => program.ID === "LR0HYZYWY6JQK");
      const pm = new ProgramModule(eprr);

      const contactID = Number(user.contents.userData.contactID);
      const roleID = ProgramUtils.getSelectedRoleIdForNWASICProgram(user.contents);

      const pMUsageData = new PMUsageData(eprr, []);

      const filteredEvidence = programEvidence.filter((ev) => !ev.draft) as [];

      const results = pMUsageData.getUsageDataByUser(pm, contactID, roleID, filteredEvidence);
      setUsageData(results);
    };

    calculateUsageData();
  }, [JSON.stringify(programEvidence)]);

  const closeGetStartedModal = async (): Promise<void> => {
    try {
      const subscriptions = user.contents?.userData.subscriptions || [];
      const programSubscriptionIDs = currentProgram.program?.Subscription || [];

      const programSubscription: IClassSubscription = subscriptions.find((item: IClassSubscription | any) => {
        const subscriptionPrograms: string[] = item.ParaFolioPrograms;

        return (
          subscriptionPrograms?.some((_sub) => program.Subscription?.includes(_sub)) ||
          programSubscriptionIDs.includes(`${item.SubscriptionID}`)
        );
      });

      if (programSubscription) {
        if (programSubscription.App_Data) {
          let json: any = {};

          try {
            json = JSON.parse(programSubscription.App_Data);
          } catch (error) {
            console.log(error);
          }

          if (!json[`${params.programId}`]?.infoDismissed) {
            const data = {
              ...json,
              [`${params.programId}`]: {
                startDate: json[`${params.programId}`]?.startDate,
                infoDismissed: true,
              },
            };

            const success = await AuthenticationController.appendSubAppData(
              user.contents?.userData.contactID,
              user.contents?.token!,
              programSubscription.SubscriberID,
              data
            );

            if (success) {
              let userToSave = await AuthenticationController.getUserData(user.contents);

              if (userToSave) {
                setUser(userToSave);
              }
            }
          }
        } else {
          let data = {
            [`${params.programId}`]: {
              infoDismissed: true,
            },
          };

          const success = await AuthenticationController.appendSubAppData(
            user.contents?.userData.contactID,
            user.contents?.token!,
            programSubscription.SubscriberID,
            data
          );

          if (success) {
            let userToSave = await AuthenticationController.getUserData(user.contents);

            if (userToSave) {
              setUser(userToSave);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addProgramEvidence = (): void => {
    const evidenceDefinitions = currentProgram.program["Evidence Definitions"] ?? [];
    const mandatoryStandards = ProgramUtils.getMandatoryStandards(user.contents, currentProgram.program);
    const optionalStandards = ProgramUtils.getOptionalStandards(user.contents, currentProgram.program);

    const data = {
      program: currentProgram.program,
      ...(currentProgram.competences.length > 0 && { competences: currentProgram.competences }),
      ...(evidenceDefinitions.length > 0 && {
        evidenceDefinitions: evidenceDefinitions[0],
      }),
      ...(currentProgram.progressChecks.length > 0 && { progressChecks: currentProgram.progressChecks }),
      ...(mandatoryStandards.length > 0 && { mandatoryStandards }),
      ...(optionalStandards.length > 0 && { optionalStandards }),
      skill: null,
    };

    FirebaseService.logEvent("program_add_enhanced_evidence", {
      program: currentProgram.program?.Name,
    });

    EventRegister.emit("evidence/add-program-evidence", data);
  };

  const addRequiredSkillEvidence = (competence: CompetenceActivityClass, skill: ProgramSkill): void => {
    const data = {
      program: currentProgram.program,
      competences: currentProgram.competences,
      competence: competence,
      skill: skill,
    };

    FirebaseService.logEvent("program_required_skill_evidence", {
      program: currentProgram.program?.Name,
      competence: competence?.Name,
      skill: skill?.Name,
    });

    EventRegister.emit("evidence/add-skill-evidence", data);
  };

  const goToEnhancedSkill = (item: CompetenceActivityClass): void => {
    const skillID = item.ID;

    FirebaseService.logEvent("program_competence_pressed", {
      program: currentProgram.program?.Name,
      competence: item.Name,
    });

    history.push(`/dashboard/program/${params.programId}/${skillID}`);
  };

  const goToProgressCheck = (item: ProgressCheck): void => {
    const progressCheckID = item.ID;

    FirebaseService.logEvent("program_progress_check_pressed", {
      program: currentProgram.program?.Name,
      progressCheck: item.Name,
    });

    history.push(`/dashboard/program/${params.programId}/progress-check/${progressCheckID}`);
  };

  const setupDetails = (): void => {
    FirebaseService.logEvent("program_setup_pressed", {
      program: currentProgram.program?.Name,
    });

    if (
      ProgramUtils.isMTS(params.programId) ||
      ProgramUtils.isCHUB(params.programId) ||
      ProgramUtils.isCSM(params.programId)
    ) {
      history.push(`/dashboard/program/${program.ID}/role-team-setup`);
    } else {
      history.push(`/dashboard/program/${params.programId}/details`);
    }
  };

  const logDocumentExport = async (pages: number): Promise<void> => {
    await FirebaseService.logEvent("evidence_exported", { pages });
  };

  const generatePdfDocument = useCallback(
    async (exportType: string, program?: ProgramData): Promise<void> => {
      try {
        const doc = pdf();

        let evidenceToExport: IEvidence[] = [];

        // All evidence for the specific program selected - e.g. HART, CHUB, etc.
        let copy = _.cloneDeep(programEvidence);

        evidenceToExport = _.sortBy(copy, (item) => new Date(item.date).getTime()).reverse();

        evidenceToExport = evidenceToExport.filter((item: IEvidence) => {
          const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
          const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

          return onHoldReset !== 1 && item.draft !== true;
        });

        doc.updateContainer(
          <ExportedPDF
            evidence={evidenceToExport}
            user={user.contents}
            isHCPC={exportType === IExportType.HCPC}
            summaryOfWork={
              exportType === IExportType.HCPC && user.state === "hasValue" ? user.contents?.summaryOfWork : undefined
            }
            personalStatement={
              exportType === IExportType.HCPC && user.state === "hasValue"
                ? user.contents?.personalStatement
                : undefined
            }
            isMajorIncident={exportType === IExportType.MAJOR_INCIDENT}
            program={program}
            usageData={usageData}
            userPrograms={userPrograms}
            programProgressCheckData={programProgressCheckData}
          />
        );

        doc.on("change", async () => {
          if (doc.container.document && doc.container.document.children.length > 0) {
            const blob = await doc.toBlob();

            try {
              await logDocumentExport(doc.container.document.children.length);
            } catch (error) {
              console.log(error);
            }

            const username = user.contents.userData.userName?.replace(/\s/gi, "-") || "Untitled";
            const programName = program?.Name?.replace(/\s/gi, "-") || "Program";
            const filename = `${username}-${programName}-Portfolio-${format(new Date(), "dd-MM-yyyy")}`;

            if (await DataController.isWebAsync()) {
              saveAs(blob, filename.trim().length > 0 ? filename.trim() : "Untitled");
            } else {
              const filePath = await WriteBlob({
                path: `ParaFolio/Exports/${DataController.getFolderFromExportType(exportType)}/${filename.trim().length > 0 ? filename.trim() : "Untitled"}.pdf`,
                blob,
                directory: Directory.Library,
                recursive: true,
              });

              FileOpener.open({
                filePath,
              });
            }

            doc.updateContainer(<Document />);
            setExportingEvidence(false);
            setCurrentExportType("");
          }
        });
      } catch (error) {
        console.log(error);
        setExportingEvidence(false);
        setCurrentExportType("");
      }
    },
    [programEvidence, user, programProgressCheckData]
  );

  const exportPressed = useCallback(
    async (exportType: string, program: ProgramData): Promise<void> => {
      try {
        if (user.state === "hasValue" && user.contents) {
          setExportingEvidence(true);
          setCurrentExportType(exportType);
          await generatePdfDocument(exportType, program);
        }
      } catch (error) {
        console.log(error);
        window.alert("An error occurred while exporting, please try again.");
        setExportingEvidence(false);
        setCurrentExportType("");
      }
    },
    [generatePdfDocument, user]
  );

  const evidenceCardClicked = (item: IEvidence): void => {
    let _evidence = _.pickBy(_.cloneDeep(item), _.identity);

    const evidenceJSON = _evidence.evidenceJSON && JSON.parse(_evidence.evidenceJSON);
    const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID;

    const programID = program?.ID;
    const progressCheckID = evidenceJSON?.programInfo?.progressCheckID;

    if (ProgramUtils.isWellbeingActivity(progressCheckCompetenceID)) {
      history.push(
        `/dashboard/program/${programID}/progress-check/${progressCheckID}/wellbeing-activity/${progressCheckCompetenceID}/results/${_evidence.id}`
      );
    } else {
      history.push(`/dashboard/program/${params.programId}/read/${_evidence.id}`);
    }
  };

  const openCertificate = async (item: IUserProgramCertificate): Promise<void> => {
    try {
      setViewingCertificate(true);
      setViewingCertificateFilename(item.certificate?.name || "");

      const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user.contents);

      const file = await AWSService.openCertificateFromServer(user.contents, accessParams, item.certificate);

      if (await DataController.isWebAsync()) {
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(file.Body.data)], {
            type: file.ContentType,
          })
        );
        window.open(url, "_blank");
      } else {
        const blob = new Blob([new Uint8Array(file.Body.data)], {
          type: file.ContentType,
        });

        const filePath = await WriteBlob({
          path: `ParaFolio/Certificates/${item.certificate?.name}.${mime.getExtension(file.ContentType)}`,
          blob,
          directory: Directory.Library,
          recursive: true,
        });

        FileOpener.open({
          filePath,
        });
      }

      setViewingCertificate(false);
      setViewingCertificateFilename("");
    } catch (error) {
      console.log(error);
      window.alert("An error occurred when opening certificate");
      setViewingCertificate(false);
      setViewingCertificateFilename("");
    }
  };

  const getCurrentProgressCheckData = (allData: AllProgressCheckStatuses | null, check: ProgressCheck) => {
    if (allData) {
      const data = allData.pCs.find((item) => item.pCId === check.ID);

      return data;
    }

    return;
  };

  const refreshUserProgramData = (): void => {
    setRefreshingData(true);

    EventRegister.emit("program/refresh-button", user.contents);
  };

  const renderMandatoryStandards = () => {
    if (user.state === "hasValue") {
      const selectedRoleID = ProgramUtils.getSelectedRoleIdForNWASICProgram(user.contents);
      const mandatoryStandards = ProgramUtils.getMandatoryStandardsFromProgram(currentProgram.program, selectedRoleID);

      if (mandatoryStandards && mandatoryStandards.length > 0) {
        return (
          <>
            <div className="text-acc-13px font-acc-400 text-grey-90">Mandatory Standards</div>
            {mandatoryStandards.map((standard) => {
              const usageResult = usageData.find((item) => item.compId === standard.id);
              const percentage = usageResult ? Number(usageResult.percentage) : 0;
              return (
                <NOSOverviewItem
                  key={standard.id}
                  title={standard.name}
                  subtitle={`${retrieveEvidenceCount(standard.id)} evidence`}
                  description={standard.description}
                  percentage={Math.floor(percentage)}
                  buttonPressed={() => {
                    history.push(`/dashboard/program/${params.programId}/standard/${standard.id}`);
                  }}
                />
              );
            })}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const renderOptionalStandards = () => {
    if (user.state === "hasValue") {
      const optionalStandards = ProgramUtils.getOptionalStandardsFromUser(user.contents, currentProgram.program);

      if (optionalStandards && optionalStandards.length > 0) {
        return (
          <>
            <div className="text-acc-13px font-acc-400 text-grey-90 mt-5">Optional Standards</div>
            {optionalStandards.map((standard) => {
              const usageResult = usageData.find((item) => item.compId === standard.id);
              const percentage = usageResult ? Number(usageResult.percentage) : 0;
              return (
                <NOSOverviewItem
                  key={standard.id}
                  title={standard.name}
                  subtitle={`${retrieveEvidenceCount(standard.id)} evidence`}
                  description={standard.description}
                  percentage={Math.floor(percentage)}
                  buttonPressed={() => {
                    history.push(`/dashboard/program/${params.programId}/standard/${standard.id}`);
                  }}
                />
              );
            })}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const retrieveEvidenceCount = (idToMatch: string) => {
    let evidenceCount = 0;
    const filteredEvidece = programEvidence
      .filter((item) => !item.draft) // filter out drafts
      .filter((item) => new Date(item.date) >= subYears(new Date(), 2)); // filter out expired evidence
    filteredEvidece.forEach((evidence) => {
      try {
        const parsed = JSON.parse(evidence.evidenceJSON);
        if (Array.from(parsed.Comps).some((item: any) => item.id === idToMatch)) evidenceCount += 1;
      } catch (error) {
        console.log("Failed to update evidence count");
      }
    });

    return evidenceCount;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{currentProgram.program?.["Short Title"] ?? ""}</HeaderTitle>
          <IonButtons slot="end">
            <IonButton className="header-button" mode="ios" onClick={() => refreshUserProgramData()}>
              {refreshingData ? (
                <IonSpinner className="w-[44px] h-[20px] text-white" />
              ) : (
                <IonImg src={Icon_Refresh} className="headerIcon" />
              )}
            </IonButton>
            {currentProgram.infoBody && (
              <IonButton className="header-button" mode="ios" onClick={() => infoClicked()}>
                <IonImg src={Button_Info_White} className="headerIcon" />
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background">
        {/* <pre>{JSON.stringify(currentProgressCheckData, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(allProgressCheckData, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(programProgressCheckData, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(progressChecks, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(currentProgressCheckData, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(isSetup, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(userRoles, null, 2)}</pre> */}
        <InfoModal
          visible={infoModalVisible}
          closeModal={() => setInfoModalVisible(false)}
          body={currentProgram.infoBody}
          program={currentProgram.program}
        />
        <EvidenceContainer style={{ paddingTop: 0, alignSelf: "center" }}>
          {ProgramUtils.showPreceptorshipDetails(currentProgram.program?.ID) && (
            <>
              <DetailsCard
                isPreceptor={false}
                name={user.contents?.name}
                email={user.contents?.email}
                preceptors={ProgramUtils.mapPreceptorNames(currentProgressCheckData?.mentors)}
                region={ProgramUtils.mapRegionNames(user.contents?.userData.Regions)}
                ESRNumber={ProgramUtils.mapESRNumber(currentProgram.program, user.contents?.subscriptions)}
                showExportButton={programEvidence.length > 0}
                isExporting={exportingEvidence}
                showBetaLabel={program.showBetaLabel}
                exportPressed={() => exportPressed(IExportType.PROGRAM, program)}
              />
            </>
          )}
          {ProgramUtils.isPreceptorship(currentProgram.program?.ID) &&
            ProgramUtils.preceptorshipComplete(
              currentProgram.progressChecks,
              allProgressCheckData.contents,
              evidence.contents,
              currentProgram.program?.ID
            ) && (
              <ProgramInfoCard
                title="Congratulations"
                subTitle="You have completed your Preceptorship! Your evidence will be accessible from here for future reference. Please export your Preceptorship for your own records."
              >
                <IonImg className="self-center w-[160px] h-[112px]" src={Image_Completion} />
              </ProgramInfoCard>
            )}
          {ProgramUtils.hasSetup(program?.ID) && (
            <>
              {isSetup ? (
                ProgramUtils.useDetailsCardInsteadOfProgramInfoCard(params.programId) ? (
                  <div className="flex justify-center">
                    <div className="accountCardInner">
                      {ProgramUtils.isMTS(program?.ID) ||
                      ProgramUtils.isCHUB(program?.ID) ||
                      ProgramUtils.isCSM(program?.ID) ? (
                        <LasDetails
                          exportPressed={exportPressed}
                          exportingEvidence={exportingEvidence}
                          currentExportType={currentExportType}
                        />
                      ) : (
                        <DetailsContentCollapsible
                          title={user && user.state === "hasValue" ? user.contents?.name! : ""}
                          program={program}
                        >
                          <ProgramRolesDisplay userRoles={userRoles} programId={program?.ID} />
                          {programCertificates.map((certificate: IUserProgramCertificate, index) => (
                            <ProgramCertificatesDisplay
                              certificate={certificate}
                              buttonPressed={() => openCertificate(certificate)}
                              key={certificate.certificate?.certificateType}
                              viewingCertificate={viewingCertificate}
                              viewingFilename={viewingCertificateFilename}
                            />
                          ))}
                          <div className="mt-[8px] mb-[4px]">
                            <div className="text-acc-13px leading-[1.54] tracking-[-0.1px] text-grey-70">{"Area"}</div>
                            <div className="text-acc-17px leading-[1.53] tracking-[-0.3px] text-grey-90">
                              {ProgramUtils.getEprrArea(user.contents?.programRoles)}
                            </div>
                          </div>
                          <div className="flex mt-3 gap-2">
                            <IonButton
                              className="flex-1 program-card-button h-[50px]"
                              onClick={() => exportPressed(IExportType.PROGRAM, program)}
                            >
                              {exportingEvidence && currentExportType === IExportType.PROGRAM ? (
                                <IonSpinner className="text-cta-blue" />
                              ) : (
                                <>
                                  <IonImg className="w-[40px] h-[40px]" src={Icon_Export_Blue} />
                                  <div className="text-[16px] font-semibold text-cta-blue">Export</div>
                                </>
                              )}
                            </IonButton>
                            <IonButton className="flex-1 program-card-button h-[50px]" onClick={() => setupDetails()}>
                              <IonImg className="w-[24px] h-[24px] mr-1" src={Icon_Pencil} />
                              <div className="text-[16px] font-semibold text-cta-blue">Edit</div>
                            </IonButton>
                          </div>
                        </DetailsContentCollapsible>
                      )}
                    </div>
                  </div>
                ) : (
                  <ProgramInfoCard
                    title={user ? `${user.state === "hasValue" ? user.contents?.name : ""}` : "Your details"}
                    subTitle={user ? (user.state === "hasValue" ? user.contents?.email : "") : undefined}
                    sideButton
                    sideButtonText="View details"
                    buttonClicked={() => setupDetails()}
                  >
                    {program.Certificates &&
                      program.Certificates.length > 0 &&
                      program?.Certificates?.map((certificate: ProgramCertificate) => (
                        <MandatoryCertificateDisplay
                          buttonPressed={() => setupDetails()}
                          certificate={certificate}
                          key={certificate.Name}
                          programCertificates={programCertificates}
                        />
                      ))}
                    {programCertificates.map((certificate: IUserProgramCertificate, index) => (
                      <ProgramCertificatesDisplay
                        certificate={certificate}
                        buttonPressed={() => openCertificate(certificate)}
                        key={certificate.certificate?.certificateType}
                        viewingCertificate={viewingCertificate}
                        viewingFilename={viewingCertificateFilename}
                      />
                    ))}
                    <ProgramRolesDisplay userRoles={userRoles} programId={params.programId} />
                    <ProgramCardButton
                      buttonPressed={() => exportPressed(IExportType.PROGRAM, currentProgram.program)}
                      buttonText={`Export ${currentProgram.program.Title}`}
                    >
                      {exportingEvidence && currentExportType === IExportType.PROGRAM ? (
                        <IonSpinner className="text-cta-blue" />
                      ) : (
                        <div className="text-[16px] font-semibold leading-[1.5] tracking-default text-center text-cta-blue">
                          {`Export ${currentProgram.program.Title}`}
                        </div>
                      )}
                    </ProgramCardButton>
                  </ProgramInfoCard>
                )
              ) : (
                <ProgramInfoCard
                  title="Set up your details"
                  subTitle={currentProgram.program?.DetailsDescription ?? undefined}
                >
                  <ProgramCardButton buttonPressed={() => setupDetails()} buttonText="Set up details" callToAction />
                </ProgramInfoCard>
              )}
            </>
          )}
          {ProgramUtils.showMandatoryStandardsInProgramHomePage(currentProgram.program?.ID) && (
            <ProgramInfoCard
              title="NOS Overview"
              subTitle={
                isSetup
                  ? ""
                  : "After selecting your role, your mandatory standards will appear here. You will also be asked to select 2 of 6 optional standards to achieve as part of your profile."
              }
            >
              {renderMandatoryStandards()}
              {renderOptionalStandards()}
            </ProgramInfoCard>
          )}
          {currentProgram.competences?.length > 0 && (
            <ProgramInfoCard
              title="Enhanced Skills"
              subTitle="Add evidence of your CPD for these enhanced skills to ensure you pass recertification"
            >
              {currentProgram.competences.map((competence: CompetenceActivityClass) => (
                <CompetenceItem
                  key={competence.ID}
                  buttonPressed={() => goToEnhancedSkill(competence)}
                  competence={competence}
                  certificates={certificates}
                  programName={currentProgram.program.Name}
                  programCertificates={programCertificates}
                />
              ))}
              <ProgramCardButton
                buttonPressed={() => addProgramEvidence()}
                buttonText="Add new evidence"
                icon={Icon_Add_Evidence}
              />
            </ProgramInfoCard>
          )}
          {currentProgram.progressChecks.length > 0 && (
            <>
              {ProgramUtils.isMTS(program?.ID) ||
              ProgramUtils.isCHUB(program?.ID) ||
              ProgramUtils.isCSM(program?.ID) ? (
                <>
                  {isSetup ? (
                    <ProgramInfoCard
                      title="Progress checks"
                      subTitle={"Complete your Courses before starting Mentoring"}
                    >
                      {currentProgram.progressChecks.map((progressCheck: ProgressCheck, i) => {
                        return (
                          <CustomProgressCheckButton
                            key={progressCheck.ID}
                            title={progressCheck.Name}
                            showSubtitle={ProgramUtils.isMTS(program?.ID)}
                            progressCheck={progressCheck}
                            programEvidence={programEvidence}
                            handleClick={() => goToProgressCheck(progressCheck)}
                            progressLabel={ProgramUtils.getProgressCheckLabelInformationForTelTriage(
                              progressCheck,
                              programEvidence,
                              currentProgram.progressChecks[i - 1]
                            )}
                          />
                        );
                      })}
                    </ProgramInfoCard>
                  ) : null}
                </>
              ) : (
                <ProgramInfoCard
                  title="Progress checks"
                  subTitle={"Please complete these in order as you progress through your Preceptorship"}
                >
                  {currentProgram.progressChecks.map((progressCheck: ProgressCheck, i) => {
                    return (
                      <ProgressCheckButton
                        key={progressCheck.ID}
                        title={progressCheck.Name}
                        buttonPressed={() => goToProgressCheck(progressCheck)}
                        evidenceCount={ProgramUtils.getEvidenceCountForProgressCheck(programEvidence, progressCheck.ID)}
                        newCommentCount={CommentHelpers.getCommentCountForProgressCheckButton(
                          user.contents,
                          currentProgressCheckData,
                          progressCheck.ID
                        )}
                        progressLabel={ProgramUtils.getProgressCheckLabelInformation(
                          progressCheck,
                          getCurrentProgressCheckData(currentProgressCheckData, progressCheck),
                          programEvidence,
                          currentProgram.progressChecks,
                          currentProgressCheckData
                        )}
                      />
                    );
                  })}
                </ProgramInfoCard>
              )}
            </>
          )}
          {ProgramUtils.showConfidenceLevelGraph(params.programId) && (
            <ProgramInfoCard
              title="Confidence levels"
              subTitle={StringUtils.getConfidenceLevelsHeader(confidenceLevelData)}
            >
              {confidenceLevelData && <ConfidenceLevelGraph data={confidenceLevelData} />}
            </ProgramInfoCard>
          )}
          {programSkills.requiredSkills?.map((skill: ProgramSkill) => (
            <RequiredSkillCard
              buttonPressed={(competence) => addRequiredSkillEvidence(competence, skill)}
              competences={currentProgram.competences}
              key={skill.ID}
              programEvidence={programEvidence}
              skill={skill}
            />
          ))}
          {ProgramUtils.isCHUB(program?.ID) && isSetup && (
            <ProgramInfoCard
              title={"Ongoing Development"}
              subTitle={"Upload a minimum of 2 pieces of any Ongoing Development competencies per month"}
            >
              <MonthlyEvidenceBreakdown evidence={programEvidence} />
            </ProgramInfoCard>
          )}
          {ProgramUtils.showEvidenceBlock(params.programId, user.contents) && (
            <>
              <ProgramInfoCard
                title="Evidence"
                style={{ paddingTop: 14, paddingBottom: 8 }}
                subTitle={
                  programEvidence.length === 0
                    ? `Any evidence added ${currentProgram.program?.Title ? `as ${currentProgram.program?.Title}` : "to this program module"} will appear below.`
                    : undefined
                }
              >
                <ProgramCardButton
                  buttonPressed={() => addProgramEvidence()}
                  buttonText="Add new evidence"
                  icon={Icon_Add_Evidence}
                />
                {ProgramUtils.isMTS(program?.ID) &&
                  currentProgram.progressChecks.every((pc) =>
                    ProgramUtils.isProgressCheckApprovedBasedOnCompTrigger(programEvidence, pc["Competence/Activity"])
                  ) && (
                    <div className="py-2 px-3 text-acc-12px font-acc-500 rounded-lg text-grey-90 bg-orange-20 mt-2">
                      You can no longer add evidence now your Mentoring has been approved.
                    </div>
                  )}
                {programEvidence.length > 0 && (
                  <div>
                    {programEvidence.map((item) => (
                      <ProgramEvidenceCard
                        key={item.id}
                        buttonPressed={() => evidenceCardClicked(item)}
                        programEvidence={item}
                        program={currentProgram.program}
                      />
                    ))}
                  </div>
                )}
              </ProgramInfoCard>
            </>
          )}
          {isDebugUser && (
            <ProgramInfoCard title="Debug Info">
              <div>{`Version ${program?.version}`}</div>
            </ProgramInfoCard>
          )}
        </EvidenceContainer>
      </IonContent>
    </IonPage>
  );
};

export default ProgramHome;
