import { IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonToolbar } from "@ionic/react";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import { HeaderTitle } from "parafolio-components";
import { useHistory, useParams } from "react-router";
import { useProgramData } from "../../hooks/data/useProgramData";
import { useEffect, useState } from "react";
import {
  AllProgressCheckStatuses,
  CompetenceActivityClass,
  IEvidence,
  LearningObjective,
  ProgressCheck,
  ProgressCheckStatus,
  SubmissionState,
} from "../../Interfaces";
import ProgramInfoCard from "../../components/home/ProgramInfoCard";
import * as ProgramUtils from "../../utils/programUtils";
import { useRecoilValueLoadable } from "recoil";
import { evidenceAtom, progressCheckDataAtom } from "../../state/State";
import ProgramEvidenceCard from "../../components/programs/ProgramEvidenceCard";
import EvidenceDisclaimer from "../../components/programs/EvidenceDisclaimer";
import _ from "lodash";
import ProgramCardButton from "../../components/programs/ProgramCardButton";
import { Icon_Add_Evidence } from "../../assets/images";
import { EventRegister } from "react-native-event-listeners";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import { Icon_Tick_Green } from "../../assets/images";

const LearningOutcomesPage: React.FC = () => {
  const params = useParams<{ programId: string; progressCheckId: string; compId: string }>();
  const history = useHistory();

  const { userPrograms } = useProgramData();

  const program = userPrograms.find((item) => item.ID === params.programId)!;

  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[]>(progressCheckDataAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const [progressCheck, setProgressCheck] = useState<ProgressCheck | any | null>(null);
  const [competenceEvidence, setCompetenceEvidence] = useState<IEvidence[]>([]);
  const [evidenceForAllCompetences, setEvidenceForAllCompetences] = useState<IEvidence[]>([]);
  const [competences, setCompetences] = useState<CompetenceActivityClass[]>([]);
  const [progressCheckData, setProgressCheckData] = useState<ProgressCheckStatus | null>(null);
  const [progressCheckEvidence, setProgressCheckEvidence] = useState<IEvidence[]>([]);
  const [currentProgressCheckData, setCurrentProgressCheckData] = useState<AllProgressCheckStatuses | null>(null);
  const [progressChecks, setProgressChecks] = useState<ProgressCheck[]>([]);

  const selectedComp = competences.find((comp) => comp.ID === params.compId);

  function checkIfAddEvidenceButtonShouldBeDisabled() {
    if (selectedComp) {
      if (ProgramUtils.isMTS(program.ID) || ProgramUtils.isCHUB(program.ID) || ProgramUtils.isCSM(program.ID)) {
        let requiredEvidenceAlreadyAdded = false;
        if (!selectedComp.MultipleAllowed) {
          const filteredEvidence = competenceEvidence.filter((e) => {
            const parsedEvidence = JSON.parse(e.evidenceJSON);
            return !parsedEvidence.OnHoldReset && !e.draft;
          });
          if (!selectedComp["Number Evidences required"]) {
            if (filteredEvidence.length >= 1) requiredEvidenceAlreadyAdded = true;
          } else {
            // include greater than because draft evidence can be saved as real evidence, which would put the evidence count over the required count
            if (filteredEvidence.length >= selectedComp["Number Evidences required"])
              requiredEvidenceAlreadyAdded = true;
          }
        }
        const progressCheckLocked = ProgramUtils.checkIfProgressCheckIsLocked(
          progressCheck,
          progressChecks,
          evidence.contents,
          currentProgressCheckData
        );

        if (ProgramUtils.isCSM(program.ID)) {
          // in CSM, evidence can be added after the progress check is approved, so just check if evidence is already added
          return requiredEvidenceAlreadyAdded;
        } else {
          return (
            progressCheckLocked ||
            ProgramUtils.isProgressCheckApprovedBasedOnCompTrigger(progressCheckEvidence, competences) ||
            requiredEvidenceAlreadyAdded
          );
        }
      } else {
        return ProgramUtils.checkIfProgressCheckIsLocked(
          progressCheck,
          progressChecks,
          evidence.contents,
          currentProgressCheckData
        );
      }
    }

    return false;
  }

  useEffect(() => {
    const getProgramData = (): void => {
      if (program) {
        const allProgressChecks: ProgressCheck[] = program.ProgressChecks ?? [];

        const _progressCheck: ProgressCheck | undefined = allProgressChecks.find(
          (item: any): item is ProgressCheck => item.ID === params.progressCheckId
        );

        setProgressChecks(allProgressChecks);

        if (_progressCheck) {
          setProgressCheck(_progressCheck);

          if (_progressCheck["Competence/Activity"]) {
            setCompetences(_progressCheck["Competence/Activity"]);
          }
        }
      }
    };

    getProgramData();
  }, [program?.version]);

  useEffect(() => {
    const getProgressCheckData = (): void => {
      const dataForProgram: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === program?.ID
      );

      const dataForProgressCheck = dataForProgram?.pCs.find((item) => item.pCId === progressCheck?.ID);

      dataForProgram && setCurrentProgressCheckData(dataForProgram);
      dataForProgressCheck && setProgressCheckData(dataForProgressCheck);
    };

    if (allProgressCheckData.state === "hasValue") {
      getProgressCheckData();
    }
  }, [allProgressCheckData, program, progressCheck]);

  useEffect(() => {
    const getEvidenceForProgressCheck = (): void => {
      let evidenceForCompetence: IEvidence[];
      evidenceForCompetence = evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const id = evidenceJSON?.programInfo?.progressCheckID || "";

        return id === progressCheck?.ID && selectedComp?.ID === evidenceJSON.programInfo.progressCheckCompetenceID;
      });

      let evidenceForAllCompetences: IEvidence[];
      evidenceForAllCompetences = evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const id = evidenceJSON?.programInfo?.progressCheckID || "";

        return id === progressCheck?.ID;
      });

      evidenceForCompetence = _.orderBy(evidenceForCompetence, "date", "desc");
      evidenceForAllCompetences = _.orderBy(evidenceForAllCompetences, "date", "desc");

      setCompetenceEvidence(evidenceForCompetence);
      setEvidenceForAllCompetences(evidenceForAllCompetences);
    };

    if (evidence.state === "hasValue" && progressCheck) {
      getEvidenceForProgressCheck();
    }
  }, [evidence, progressCheck]);

  useEffect(() => {
    const getEvidenceForProgressCheck = (): void => {
      let evidenceForProgressCheck: IEvidence[];

      evidenceForProgressCheck = evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const id = evidenceJSON?.programInfo?.progressCheckID || "";

        return id === progressCheck?.ID;
      });

      evidenceForProgressCheck = _.orderBy(evidenceForProgressCheck, "date", "desc");

      setProgressCheckEvidence(evidenceForProgressCheck);
    };

    if (evidence.state === "hasValue" && progressCheck) {
      getEvidenceForProgressCheck();
    }
  }, [evidence, progressCheck]);

  useEffect(() => {
    const checkAllEvidenceAddedForProgressCheck = (): void => {
      let evidenceForProgressCheck: IEvidence[] = _.cloneDeep(progressCheckEvidence);

      evidenceForProgressCheck = evidenceForProgressCheck.filter((item) => {
        const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);

        const onHoldReset = evidenceJSON?.OnHoldReset === 1;

        return !onHoldReset && item.draft !== true;
      });

      const allCompetences = _.cloneDeep(competences);

      const progressCheckIDs = evidenceForProgressCheck
        .map((item: IEvidence) => {
          const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);

          return evidenceJSON?.programInfo?.progressCheckCompetenceID;
        })
        .sort();

      const competenceIDs = allCompetences.map((item) => item.ID).sort();
    };

    if (progressCheckEvidence.length > 0) {
      checkAllEvidenceAddedForProgressCheck();
    }
  }, [competences, progressCheckEvidence]);

  const evidenceCardPressed = (item: IEvidence): void => {
    let _evidence = _.pickBy(_.cloneDeep(item), _.identity);

    const programID = program?.ID;
    const progressCheckID = progressCheck?.ID;

    history.push(`/dashboard/program/${programID}/progress-check/${progressCheckID}/read/${_evidence.id}`);
  };

  const addEvidencePressed = (competence?: CompetenceActivityClass): void => {
    const progressChecks = program.ProgressChecks ?? [];

    const data = {
      program: program,
      // progressCompetences: competences, // commented out because these were overwriting removeProgressCheckCompetences() behaviour
      ...(competence && { progressCompetence: competence }),
      progressCheck: progressCheck,
      ...(progressChecks.length > 0 && { progressChecks }),
      learningOutcomes: competence?.["Learning Objective"] ?? [],
      progressCompetence: selectedComp,
    };

    EventRegister.emit("evidence/add-progress-check-evidence", data);
  };

  function getOccurencesOfLearningOutcomesAdded(learningOutcome: LearningObjective) {
    let sum = 0;
    const filteredEvidence = progressCheckEvidence.filter((e) => {
      const parsedEvidence = JSON.parse(e.evidenceJSON);
      return !parsedEvidence.OnHoldReset && !e.draft;
    });
    filteredEvidence.forEach((evidence) => {
      const parsedEvidence = JSON.parse(evidence.evidenceJSON);
      if (parsedEvidence["Comp Selector"]) {
        Array.from(parsedEvidence["Comp Selector"]).forEach((it: any) => {
          it.selectedOutcomes.forEach((outcome: any) => {
            if (outcome.id === learningOutcome.ID) {
              sum += 1;
            }
          });
        });
      }
      if (parsedEvidence["LearningOutcomes"]) {
        Array.from(parsedEvidence["LearningOutcomes"]).forEach((outcome: any) => {
          if (outcome.ID === learningOutcome.ID) {
            sum += 1;
          }
        });
      }
    });

    return sum;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref={`/dashboard/program/${program?.ID}`}
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{selectedComp?.Name ?? ""}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background">
        <div className="pb-[96px]">
          {/* <pre>{JSON.stringify(progressCheck, null, 2)}</pre> */}
          <ProgramInfoCard
            title={selectedComp?.Name ?? ""}
            subTitle={selectedComp?.Description ?? "Add your Learning Outcomes"}
          >
            {progressCheck &&
              !ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions) &&
              ProgramUtils.checkIfProgressCheckIsLocked(
                progressCheck,
                progressChecks,
                evidence.contents,
                currentProgressCheckData
              ) && (
                <div className="mb-[8px]">
                  <EvidenceDisclaimer message={ProgramUtils.getProgressCheckLockedDisclaimerText(progressCheck.ID)} />
                </div>
              )}
            <div className="mt-2 font-acc-15px font-acc-700">Learning Outcomes</div>
            {selectedComp?.["Learning Objective"]?.map((lo) => {
              const occurences = getOccurencesOfLearningOutcomesAdded(lo);
              const occurencesRequired = lo.NumberRequired ?? 0;
              return (
                <IonItem
                  key={lo.ID}
                  button={false}
                  className="preceptee-item-button flex flex-row !justify-between items-center"
                  detail={false}
                  onClick={() => {}}
                >
                  <div className="min-h-[44px] w-full flex flex-row !justify-between items-center py-[10px]">
                    <div className="flex-1">
                      <div className="text-acc-15px font-acc-400 leading-[1.33] tracking-[-0.3px] text-grey-90">
                        {lo.Name}
                      </div>
                      <div className="text-acc-11px font-acc-500 leading-[1.33] tracking-[-0.3px] text-grey-60">
                        {occurencesRequired ? "Mandatory" : "Optional"}
                      </div>
                    </div>
                    <div className="flex items-center">
                      {occurences >= occurencesRequired && occurencesRequired !== 0 && (
                        <img src={Icon_Tick_Green} className="mr-2 h-[18px] w-[18px]" alt="" />
                      )}
                      <div
                        className={`text-acc-15px font-acc-700 ${occurences ? "text-green-light-text" : "text-grey-50"}`}
                      >
                        {`${occurences} / ${occurencesRequired}`}
                      </div>
                    </div>
                  </div>
                </IonItem>
              );
            })}
            <ProgramCardButton
              buttonPressed={() => addEvidencePressed()}
              buttonText="Add new evidence"
              icon={Icon_Add_Evidence}
              disabled={checkIfAddEvidenceButtonShouldBeDisabled()}
            />
          </ProgramInfoCard>
          <ProgramInfoCard
            title="Evidence"
            subTitle={
              competenceEvidence.length === 0
                ? "Any evidence added for this progress check will appear below."
                : undefined
            }
          >
            {!ProgramUtils.canAddOrEditProgressCheckEvidence(progressCheckData?.submissions) &&
              !ProgramUtils.hideDisclaimerText(program.ID) &&
              progressCheckData?.submissions.status !== SubmissionState.OnHoldReset && (
                <div>
                  <EvidenceDisclaimer
                    message={ProgramUtils.getEvidenceDisclaimerText(progressCheckData?.submissions)}
                  />
                </div>
              )}
            {competenceEvidence.map((item) => (
              <ProgramEvidenceCard
                key={item.id}
                buttonPressed={() => evidenceCardPressed(item)}
                programEvidence={item}
              />
            ))}
          </ProgramInfoCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LearningOutcomesPage;
