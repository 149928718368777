import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  AWSAccessParams,
  AllProgressCheckStatuses,
  CPDActivityType,
  CompetenceActivityClass,
  EvidenceAttachment,
  EvidenceCustomTag,
  EvidencePart,
  EvidenceStandardTag,
  FieldType,
  IAppFeatures,
  IEvidence,
  IEvidenceDraft,
  IEvidenceModal,
  INewDeepLinkEvidence,
  INewGuidelineEvidence,
  INewTimelineEvidence,
  IUser,
  ProgramData,
  ProgramSkill,
  ProgressCheck,
  ProgressCheckApproval,
} from "../../Interfaces";
import { useRecoilStateLoadable, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { appFeaturesAtom, evidenceAtom, evidenceDraftsAtom, progressCheckDataAtom, userAtom } from "../../state/State";
import ContentContainer from "./ContentContainer";
import "./CommonComponents.css";
import EvidenceContentCard from "./EvidenceContentCard";
import { Button_Dropdown, Icon_Attachment_Delete } from "../../assets/images";
import { format, isSameDay, isValid } from "date-fns";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import _ from "lodash";
import { EventRegister } from "react-native-event-listeners";
import roles from "../../assets/json/roles.json";
import standards from "../../assets/json/standards.json";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import DataController from "../../controllers/DataController";
import { useFilePicker } from "use-file-picker";
import { DatabaseService } from "../../controllers/DatabaseService";
import AWSService from "../../controllers/AWSService";
import { FirebaseService } from "../../controllers/FirebaseService";
import { Capacitor } from "@capacitor/core";
import { useMaskito } from "@maskito/react";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";
import EvidenceHeader from "./EvidenceHeader";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import * as ProgramUtils from "../../utils/programUtils";
import SaveDraftModal from "../evidence/SaveDraftModal";
import EmptyTagModal from "../evidence/EmptyTagModal";
import ExistingTagModal from "../evidence/ExistingTagModal";
import * as EditorUtils from "../../utils/editorUtils";
import { FileUtils } from "../../utils/fileUtils";
import { ProgressCheckService } from "../../controllers/ProgressCheckService";
import DeleteEvidenceModal from "../evidence/DeleteEvidenceModal";
import AttachmentProgressIndicator from "../evidence/AttachmentProgressIndicator";
import UnsavedChangesModal from "../evidence/UnsavedChangesModal";
import { EVIDENCE_ACCEPTED_FILE_TYPES, EVIDENCE_MAX_FILE_SIZE } from "../../Constants";
import SaveEvidenceButton from "../evidence/SaveEvidenceButton";
import AuditTickbox from "../evidence/AuditTickbox";
import AttachmentInput from "../evidence/AttachmentInput";
import ProgramModuleEvidencePart from "../evidence/ProgramModuleEvidencePart";

const EvidenceModal = ({ visible, closeModal }: IEvidenceModal) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openFileSelector, { filesContent, clear, plainFiles, errors }] = useFilePicker({
    accept: EVIDENCE_ACCEPTED_FILE_TYPES,
    maxFileSize: EVIDENCE_MAX_FILE_SIZE,
    readAs: "DataURL",
    multiple: true,
  });

  const titleRef = useRef<HTMLInputElement>(null);
  const customTagsRef = useRef<HTMLInputElement>(null);
  const standardTagsRef = useRef<HTMLInputElement>(null);
  const activityDescriptionRef = useRef<HTMLInputElement>(null);
  const reflectionsEditorRef = useRef<TinyMCEEditor | null>(null);
  const benefitsEditorRef = useRef<TinyMCEEditor | null>(null);
  const objectivesEditorRef = useRef<TinyMCEEditor | null>(null);

  const activityTypes: CPDActivityType[] = EvidenceUtils.getDefaultActivityTypes();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const [_e, setEvidence] = useRecoilStateLoadable<IEvidence[] | null>(evidenceAtom);
  const [allProgressCheckData, setAllProgressCheckData] =
    useRecoilStateLoadable<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  const appFeatures = useRecoilValue<IAppFeatures[] | string[]>(appFeaturesAtom);

  const [initialEvidence, setInitialEvidence] = useState<IEvidence | null>(null);
  const [timelineReference, setTimelineReference] = useState<string>("");

  const [isNewEvidence, setIsNewEvidence] = useState<boolean>(true);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(visible);

  // General evidence inputs
  const [title, setTitle] = useState<string>("");
  const [date, setDate] = useState<Date>(new Date());
  const [activityDate, setActivityDate] = useState<Date>(new Date());
  const [activity, setActivity] = useState<CPDActivityType | null>(null);
  const [numberOfHours, setNumberOfHours] = useState<string>("");
  const [customTags, setCustomTags] = useState<EvidenceCustomTag[]>([]);
  const [customTag, setCustomTag] = useState<string>("");
  const [attachments, setAttachments] = useState<EvidenceAttachment[]>([]);
  const [activityDescription, setActivityDescription] = useState<string>("");
  const [reflections, setReflection] = useState<string>("");
  const [benefits, setBenefits] = useState<string>("");
  const [objectives, setObjectives] = useState<string>("");
  const [addToHCPCAudit, setAddToHCPCAudit] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  // SWAST MIC inputs
  const [optionalStandards, setOptionalStandards] = useState<any[] | undefined>([]);
  const [optionalStandardsFiltered, setOptionalStandardsFiltered] = useState<any[] | undefined>([]);
  const [mandatoryStandards, setMandatoryStandards] = useState<any[] | undefined>([]);
  const [mandatoryStandardsFiltered, setMandatoryStandardsFiltered] = useState<any[] | undefined>([]);
  const [standardTags, setStandardTags] = useState<EvidenceStandardTag[]>([]);
  const [standardTag, setStandardTag] = useState<string>("");

  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const [activityDatePickerVisible, setActivityDatePickerVisible] = useState<boolean>(false);
  const [showStandardTags, setShowStandardTags] = useState<boolean>(false);

  const [attachmentsToAdd, setAttachmentsToAdd] = useState<EvidenceAttachment[]>([]);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState<EvidenceAttachment[]>([]);
  const [handlingAttachments, setHandlingAttachments] = useState<boolean>(false);
  const [viewingAttachment, setViewingAttachment] = useState<boolean>(false);
  const [viewingAttachmentFilename, setViewingAttachmentFilename] = useState<string>("");

  const [reflectionsFocused, setReflectionsFocused] = useState<boolean>(false);
  const [benefitsFocused, setBenefitsFocused] = useState<boolean>(false);
  const [objectivesFocused, setObjectivesFocused] = useState<boolean>(false);
  const [richTextBoxFocused, setRichTextBoxFocused] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);
  const [savingDraft, setSavingDraft] = useState<boolean>(false);

  const [canSaveDraft, setCanSaveDraft] = useState<boolean>(false);
  const [saveDraftModalVisible, setSaveDraftModalVisible] = useState<boolean>(false);
  const [deleteEvidenceModalVisible, setDeleteEvidenceModalVisible] = useState<boolean>(false);
  const [emptyTagModalVisible, setEmptyTagModalVisible] = useState<boolean>(false);
  const [existingTagModalVisible, setExistingTagModalVisible] = useState<boolean>(false);
  const [unsavedChangesModalVisible, setUnsavedChangesModalVisible] = useState<boolean>(false);

  const [keyboardActive, setKeyboardActive] = useState<boolean>(false);
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  // Program evidence
  const [program, setProgram] = useState<ProgramData | null>(null);
  const [competence, setCompetence] = useState<CompetenceActivityClass | null>(null);
  const [competences, setCompetences] = useState<CompetenceActivityClass[] | any[]>([]);
  const [enhancedSkill, setEnhancedSkill] = useState<ProgramSkill | null>(null);
  const [evidenceParts, setEvidenceParts] = useState<EvidencePart[] | any[]>([]);
  const [progressCheck, setProgressCheck] = useState<ProgressCheck | null>(null);
  const [progressChecks, setProgressChecks] = useState<ProgressCheck[] | any[]>([]);
  const [progressCheckCompetences, setProgressCheckCompetences] = useState<CompetenceActivityClass[] | any[]>([]);
  const [progressCheckCompetence, setProgressCheckCompetence] = useState<CompetenceActivityClass | null>(null);
  const [programMandatoryStandards, setProgramMandatoryStandards] = useState<CompetenceActivityClass[]>([]);
  const [programOptionalStandards, setProgramOptionalStandards] = useState<CompetenceActivityClass[]>([]);

  const [evidenceJSON, setEvidenceJSON] = useState<any>({});
  const [initialEvidenceJSON, setInitialEvidenceJSON] = useState<any>({});

  // Confirmation modal for changing enhanced skill
  const [changeTaskingTypeModalVisible, setChangeTaskingTypeModalVisible] = useState<boolean>(false);
  const [tempTaskingType, setTempTaskingType] = useState<string>("");

  // Confirmation modal for changing progress check type
  const [changeProgressCheckModal, setChangeProgressCheckModalVisible] = useState<boolean>(false);
  const [tempProgressCheck, setTempProgressCheck] = useState<string>("");

  // Confirmation modal for changing progress check competence
  const [changeProgressCheckCompetenceModal, setChangeProgressCheckCompetenceModalVisible] = useState<boolean>(false);
  const [tempProgressCheckCompetence, setTempProgressCheckCompetence] = useState<string>("");

  const [tempInput, setTempInput] = useState<string>("");

  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        setKeyboardActive(true);
        setKeyboardHeight(info.keyboardHeight);
      });

      Keyboard.addListener("keyboardWillHide", () => {
        setKeyboardActive(false);
      });
    }
  }, []);

  useEffect(() => {
    if (progressCheck) {
      getProgressCheckCompetences(progressCheck);
    }
  }, [progressCheck]);

  useEffect(() => {
    if (plainFiles.length > 0) {
      const result = EvidenceUtils.addAttachmentToUploadArray(plainFiles, filesContent, attachments, attachmentsToAdd);

      setAttachments(result.attachments);
      setAttachmentsToAdd(_.uniqBy(result.toAdd, "name"));
      clear();
    }
  }, [attachments, attachmentsToAdd, attachmentsToDelete, clear, filesContent, plainFiles]);

  useEffect(() => {
    const checkAttachments = () => {
      if (attachments.length > 0) {
        const toAdd = _.cloneDeep(attachmentsToAdd);

        for (let i = 0; i < attachments.length; i++) {
          const attachment = attachments[i];
          const includeAdd = toAdd.findIndex((item) => item.name === attachment.name);
          const includeDelete = attachmentsToDelete.findIndex((item) => item.name === attachment.name);

          if (includeAdd === -1 && includeDelete === -1 && attachment.content) {
            toAdd.push(attachment);
          }
        }

        setAttachmentsToAdd(_.uniqBy(toAdd, "name"));
      }
    };

    const checkAttachmentsNeedUploading = () => {
      let _attachments = _.cloneDeep(attachments);
      _attachments = _attachments.filter((item) => item.content);
      return _attachments.length > 0;
    };

    if (attachmentsToAdd.length === 0 && checkAttachmentsNeedUploading()) {
      checkAttachments();
    }
  }, [attachments, attachmentsToAdd, attachmentsToDelete]);

  useEffect(() => {
    const checkAttachments = () => {
      if (attachments.length > 0) {
        const toAdd = _.cloneDeep(attachmentsToAdd);

        for (let i = 0; i < attachments.length; i++) {
          const attachment = attachments[i];
          const includeAdd = toAdd.findIndex((item) => item.name === attachment.name);
          const includeDelete = attachmentsToDelete.findIndex((item) => item.name === attachment.name);

          if (includeAdd === -1 && includeDelete === -1 && attachment.content) {
            toAdd.push(attachment);
          }
        }

        setAttachmentsToAdd(_.uniqBy(toAdd, "name"));
      }
    };

    const checkAttachmentsNeedUploading = () => {
      let _attachments = _.cloneDeep(attachments);
      _attachments = _attachments.filter((item) => item.content);
      return _attachments.length > 0;
    };

    if (attachmentsToAdd.length === 0 && checkAttachmentsNeedUploading()) {
      checkAttachments();
    }
  }, [attachments, attachmentsToAdd, attachmentsToDelete]);

  useEffect(() => {
    const handleClick = (event: Event): void => {
      const element: any = document.getElementById("reflectionsStandardTags");
      const target = event.target as HTMLInputElement;
      const classList = event.target && target.classList;

      // if (!classList?.contains('standardTagButton')) {
      if (!element?.contains(event.target) && !classList?.contains("standardTagButton")) {
        setShowStandardTags(false);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const getProgressCheckCompetences = useCallback(
    (_progressCheck: ProgressCheck): void => {
      if (program) {
        let _competences = _.cloneDeep(_progressCheck["Competence/Activity"]);
        _competences = ProgramUtils.removeProgressCheckCompetences(
          _competences,
          _progressCheck,
          _e.contents,
          program.ID,
          progressCheckCompetence
        );

        setProgressCheckCompetences(_competences);
      }
    },
    [_e.contents, program?.ID]
  );

  const getIncompleteProgressChecks = useCallback(
    (_progressChecks: ProgressCheck[], _program: ProgramData): void => {
      let array = _.cloneDeep(_progressChecks);
      array = ProgramUtils.removeProgressChecks(array, _e.contents, _program, allProgressCheckData.contents);

      setProgressChecks(array);
    },
    [_e.contents, allProgressCheckData.contents]
  );

  useEffect(() => {
    EventRegister.addEventListener("evidence-modal/add-evidence-guideline", (object: INewGuidelineEvidence) => {
      setTitle(object.title || "");
    });

    EventRegister.addEventListener("evidence-modal/add-evidence-timeline", (object: INewTimelineEvidence) => {
      setTitle(object.title);
      setDate(object.date);
      setActivityDate(object.activityDate);
      setActivityDescription(object.activityDescription);
      setTimelineReference(object.timelineReference);
    });

    EventRegister.addEventListener("evidence-modal/add-evidence-deeplink", (object: INewDeepLinkEvidence) => {
      setTitle(object.title ?? "");
      setDate(object.date ? new Date(object.date) : new Date());
      setActivityDate(object.activityDate ? new Date(object.activityDate) : new Date());
      setActivityDescription(object.activityDescription ?? "");
      setTimelineReference(object.timelineReference ?? "");
      setNumberOfHours(object.activityDuration ? DataController.convertToHoursMinutes(object.activityDuration) : "");
      setActivity(object.activityType ? { type: object.activityType } : null);
      setReflection(object.reflection ?? "");
      setBenefits(object.benefits ?? "");
      setObjectives(object.learningObjectives ?? "");
    });

    EventRegister.addEventListener("timeline/edit-evidence-clicked", (object: IEvidence) => {
      setTitle(object.title || "");
      setDate(new Date(object.date));
      object.activityDate && setActivityDate(new Date(object.activityDate));
      setActivity(!_.isEmpty(object.activity) ? object.activity : null);
      setNumberOfHours((object.numberOfHours && DataController.convertToHoursMinutes(object.numberOfHours)) || "");
      object.standardTags && setStandardTags(object.standardTags);
      object.customTags && setCustomTags(object.customTags);
      object.attachments && setAttachments(object.attachments);
      setActivityDescription(object.activityDescription || "");
      setReflection(object.reflections || "");
      setBenefits(object.benefits || "");
      setObjectives(object.objectives || "");
      setId(object.id);
      object.addToHCPCAudit !== undefined && setAddToHCPCAudit(object.addToHCPCAudit);
      object.timelineReference && setTimelineReference(object.timelineReference);
      setIsDraft(object.draft === true);

      setInitialEvidence(object);
      setIsNewEvidence(false);
    });

    EventRegister.addEventListener("evidence/evidence-card-clicked", (object: IEvidence) => {
      setTitle(object.title || "");
      setDate(new Date(object.date));
      object.activityDate && setActivityDate(new Date(object.activityDate));
      setActivity(!_.isEmpty(object.activity) ? object.activity : null);
      setNumberOfHours((object.numberOfHours && DataController.convertToHoursMinutes(object.numberOfHours)) || "");
      object.standardTags && setStandardTags(object.standardTags);
      object.customTags && setCustomTags(object.customTags);
      object.attachments && setAttachments(object.attachments);
      setActivityDescription(object.activityDescription || "");
      setReflection(object.reflections || "");
      setBenefits(object.benefits || "");
      setObjectives(object.objectives || "");
      setId(object.id);
      object.addToHCPCAudit !== undefined && setAddToHCPCAudit(object.addToHCPCAudit);
      object.timelineReference && setTimelineReference(object.timelineReference);

      setInitialEvidence(object);
      setIsDraft(object.draft === true);
      setIsNewEvidence(false);
    });

    EventRegister.addEventListener("evidence/add-program-evidence", (data) => {
      data.program && setProgram(data.program);
      data.skill && setEnhancedSkill(data.skill);
      data.competences && setCompetences(data.competences);
      data.progressCompetences && setProgressCheckCompetences(data.progressCompetences);

      if (data.evidenceJSON) {
        setDate(DataController.getDefaultDateFromProgramEvidence(data.evidenceJSON));
        setEvidenceJSON(data.evidenceJSON);
        setInitialEvidenceJSON(data.evidenceJSON);
        setIsNewEvidence(false);
        setIsDraft(data?.evidence?.draft === true);
        setNumberOfHours(
          (data?.evidence?.numberOfHours && DataController.convertToHoursMinutes(data?.evidence?.numberOfHours)) || ""
        );
        data?.evidence?.addToHCPCAudit !== undefined && setAddToHCPCAudit(data.evidence.addToHCPCAudit);
        setId(data.id);
      }

      if (data.competence) {
        setCompetence(data.competence);
        getEvidenceParts(data.competence);
      }

      if (data.progressCheck && data.progressCheck["Competence/Activity"]) {
        setProgressCheck(data.progressCheck);
        getProgressCheckCompetences(data.progressCheck);
      }

      if (ProgramUtils.isCSM(data.program.ID) && data.progressChecks) {
        setProgressChecks(data.progressChecks);
      } else if (data.progressChecks) {
        getIncompleteProgressChecks(data.progressChecks, data.program);
      }

      if (data.progressCompetence) {
        setProgressCheckCompetence(data.progressCompetence);
        getEvidenceParts(data.progressCompetence);
      }

      if (data.mandatoryStandards) {
        setProgramMandatoryStandards(data.mandatoryStandards);
      }

      if (data.optionalStandards) {
        setProgramOptionalStandards(data.optionalStandards);
      }

      if (data.evidenceDefinitions) {
        setEvidenceParts(data.evidenceDefinitions?.["Evidence parts"]);
      }

      if (data.selectedStandard) {
        const newEvidenceObject = EvidenceUtils.addSelectedStandard(evidenceJSON, data.selectedStandard);
        setEvidenceJSON(newEvidenceObject);
      }
    });

    EventRegister.addEventListener("evidence/add-skill-evidence", (data) => {
      data.program && setProgram(data.program);
      data.skill && setEnhancedSkill(data.skill);
      data.competences && setCompetences(data.competences);

      if (data.competence) {
        setCompetence(data.competence);
        getEvidenceParts(data.competence);

        const newEvidenceObject = EvidenceUtils.addRequiredSkill(evidenceJSON, data.skill, data.competence);
        setEvidenceJSON(newEvidenceObject);
      }
    });

    EventRegister.addEventListener("evidence/add-progress-check-evidence", (data) => {
      data.program && setProgram(data.program);
      data.progressCheck && setProgressCheck(data.progressCheck);
      data.progressChecks && setProgressChecks(data.progressChecks);
      data.progressCompetences && setProgressCheckCompetences(data.progressCompetences);

      if (data.progressChecks && !ProgramUtils.isCSM(data.program.ID)) {
        getIncompleteProgressChecks(data.progressChecks, data.program);
      }

      if (data.progressCompetence) {
        setProgressCheckCompetence(data.progressCompetence);
        getEvidenceParts(data.progressCompetence);
        getProgressCheckCompetences(data.progressCheck);
      } else if (data.progressCheck && data.progressCheck["Competence/Activity"]) {
        getProgressCheckCompetences(data.progressCheck);
      }
    });

    return () => {
      EventRegister.removeEventListener("evidence-modal/add-evidence-guideline");
      EventRegister.removeEventListener("evidence-modal/add-evidence-timeline");
      EventRegister.removeEventListener("evidence-modal/add-evidence-deeplink");
      EventRegister.removeEventListener("evidence/evidence-card-clicked");
      EventRegister.removeEventListener("evidence/add-program-evidence");
      EventRegister.removeEventListener("evidence/add-skill-evidence");
      EventRegister.removeEventListener("evidence/add-progress-check-evidence");
      EventRegister.removeEventListener("timeline/edit-evidence-clicked");
    };
  }, [evidenceJSON, getIncompleteProgressChecks, getProgressCheckCompetences]);

  useEffect(() => {
    setModalVisible(visible);
  }, [visible]);

  useEffect(() => {
    const getStandardTagsForRole = (): void => {
      if (user.state === "hasValue" && user.contents?.role) {
        const result = EvidenceUtils.getStandardTagsForRole(roles, user.contents.role, standards, initialEvidence);

        setOptionalStandards(_.orderBy(result.optionalRoles, ["Code"], ["asc"]) || []);
        setOptionalStandardsFiltered(_.orderBy(result.filteredOptional, ["Code"], ["asc"]) || []);
        setMandatoryStandards(_.orderBy(result.mandatoryRoles, ["Code"], ["asc"]) || []);
        setMandatoryStandardsFiltered(_.orderBy(result.filteredMandatory, ["Code"], ["asc"]) || []);
      }
    };

    getStandardTagsForRole();
  }, [initialEvidence, user, visible]);

  const deleteEvidence = async (): Promise<void> => {
    if (user.state === "hasValue" && user.contents) {
      try {
        if (id) {
          if (attachments.length > 0) {
            const deleted = await deleteAttachments(attachments, id);
          }

          let deleteFromDB = await DatabaseService.deleteEvidence(user.contents, id);

          if (deleteFromDB) {
            let allEvidence = [];
            allEvidence = await DatabaseService.getEvidence(user.contents);
            await DataController.saveEvidence(allEvidence);

            setEvidence(allEvidence);

            EventRegister.emit("evidence/evidence-deleted");
            dismissModal();
          }
        }
      } catch (error) {
        window.alert("An error occurred when deleting evidence");
      }
    }
  };

  const uploadAttachments = async (_attachments: EvidenceAttachment[], id: string): Promise<EvidenceAttachment[]> => {
    const toUpload = _.cloneDeep(_attachments);
    const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user.contents);

    const uploaded = [];

    for (let i = 0; i < toUpload.length; i++) {
      let file = await AWSService.uploadAttachment(user.contents, accessParams, id, toUpload[i]);

      if (file && file.networkIssue) {
        file = await AWSService.uploadAttachmentFromServer(user.contents, accessParams, id, toUpload[i]);
      }

      const updatedFile: EvidenceAttachment = {
        ...toUpload[i],
        content: undefined,
        url: file.Location,
      };

      delete updatedFile.content;

      uploaded.push(updatedFile);
    }

    return uploaded;
  };

  const deleteAttachments = async (_attachments: EvidenceAttachment[], id: string): Promise<string[]> => {
    const toDelete = _.cloneDeep(_attachments);
    const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user.contents);

    const deleted = [];

    for (let i = 0; i < toDelete.length; i++) {
      const fileDeleted = await AWSService.deleteAttachment(user.contents, accessParams, id, toDelete[i]);

      if (fileDeleted) {
        deleted.push(toDelete[i].name);
      }
    }

    return deleted;
  };

  const saveEvidence = async (isDraftEvidence?: boolean): Promise<void> => {
    try {
      isDraftEvidence ? setSavingDraft(true) : setSaving(true);

      if (user.state === "hasValue" && user.contents) {
        if (program) {
          let evidence: IEvidence = {
            date: date.toISOString(),
            ...(addToHCPCAudit !== undefined && { addToHCPCAudit }),
            ...(numberOfHours !== "" && {
              numberOfHours: DataController.convertToNumberOfHours(numberOfHours),
            }),
            evidenceJSON: {
              ...evidenceJSON,
              programInfo: {
                program: program.Name,
                programID: program.ID,
                competence: competence?.Name,
                competenceID: competence?.ID,
                progressCheck: progressCheck?.Name,
                progressCheckID: progressCheck?.ID,
                progressCheckCompetence: progressCheckCompetence?.Name,
                progressCheckCompetenceID: progressCheckCompetence?.ID,
              },
            },
            id: id || DataController.generateUniqueID(),
            draft: isDraftEvidence ?? false,
          };

          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          evidence.evidenceJSON = EvidenceUtils.deleteNonRequiredEvidencePart(evidence.evidenceJSON, evidenceParts);

          if (progressCheckCompetence && !EvidenceUtils.checkEvidenceJSONHasTitle(evidence.evidenceJSON)) {
            evidence.evidenceJSON = EvidenceUtils.addTitleToProgressCheck(
              evidence.evidenceJSON,
              progressCheckCompetence
            );
          } else if (isDraftEvidence && !EvidenceUtils.checkEvidenceJSONHasTitle(evidence.evidenceJSON)) {
            const draftsLength = EvidenceUtils.getDraftsCount(_e.contents);

            evidence.evidenceJSON.Title = `My Draft #${draftsLength ? draftsLength + 1 : "1"}`;
          }

          try {
            // Find if there are any Evidence Parts that can hold attachments
            const attachmentPartKey = ProgramUtils.getEvidenceAttachmentsKey(evidenceParts);

            if (attachmentPartKey) {
              // Check if user has added attachments
              const hasAttachments = typeof evidence.evidenceJSON[attachmentPartKey] !== "undefined";

              if (hasAttachments) {
                // There are attachments present so these can be processed

                if (evidence.evidenceJSON[attachmentPartKey].attachmentsToAdd?.length > 0) {
                  setHandlingAttachments(true);
                  let _attachments = _.clone(evidence.evidenceJSON[attachmentPartKey].attachments) || [];

                  const uploaded = await uploadAttachments(
                    evidence.evidenceJSON[attachmentPartKey].attachmentsToAdd,
                    evidence.id
                  );

                  for (let i = 0; i < uploaded.length; i++) {
                    let index = _attachments?.findIndex((item: EvidenceAttachment) => item.name === uploaded[i].name);

                    if (index > -1) {
                      _attachments[index] = uploaded[i];
                    } else {
                      _attachments.push(uploaded[i]);
                    }
                  }

                  evidence.evidenceJSON[attachmentPartKey].attachments = _attachments;
                  delete evidence.evidenceJSON[attachmentPartKey].attachmentsToAdd;
                } else {
                  delete evidence.evidenceJSON[attachmentPartKey].attachmentsToAdd;
                }

                if (evidence.evidenceJSON[attachmentPartKey].attachmentsToDelete?.length > 0) {
                  setHandlingAttachments(true);
                  let _attachments = _.clone(evidence.evidenceJSON[attachmentPartKey].attachments) || [];

                  const deleted = await deleteAttachments(
                    evidence.evidenceJSON[attachmentPartKey].attachmentsToDelete,
                    evidence.id
                  );

                  for (let i = 0; i < deleted.length; i++) {
                    let index = _attachments?.findIndex((item: EvidenceAttachment) => item.name === deleted[i]);

                    // console.log(index);

                    if (typeof index === "number" && index > -1) {
                      _attachments?.splice(index, 1);
                    }
                  }

                  evidence.evidenceJSON[attachmentPartKey].attachments = _attachments;
                  delete evidence.evidenceJSON[attachmentPartKey].attachmentsToDelete;
                } else {
                  delete evidence.evidenceJSON[attachmentPartKey].attachmentsToDelete;
                }
              }
            }
          } catch (error) {
            console.log(error);
          }

          EventRegister.emit("evidence/clear-all-progress");
          setHandlingAttachments(false);

          try {
            await FirebaseService.logEvent("program_evidence_added", {
              program: program?.Name,
              competence: competence?.Name,
              includedInAudit: addToHCPCAudit,
              draft: isDraftEvidence ?? false,
            });
          } catch (error) {
            console.log(error);
          }

          try {
            let addToDB = await DatabaseService.addProgramEvidence(user.contents, evidence);

            if (!addToDB) {
              addToDB = await DatabaseService.updateProgramEvidence(user.contents, evidence);
            }

            if (addToDB) {
              let allEvidence = [];
              allEvidence = await DatabaseService.getEvidence(user.contents);
              await DataController.saveEvidence(allEvidence);

              if (evidence.evidenceJSON.programInfo.progressCheckID) {
                const progressCheckStatus = ProgramUtils.getProgressCheckStatusFromEvidence(
                  evidence.evidenceJSON.programInfo.progressCheckID,
                  allProgressCheckData.contents,
                  program
                );

                if (!isDraftEvidence) {
                  if (!progressCheckStatus) {
                    // add new progress check

                    let progressCheckCreated;

                    if (
                      ProgramUtils.autoCompleteProgressCheck(
                        evidence.evidenceJSON.programInfo.progressCheckCompetenceID
                      )
                    ) {
                      progressCheckCreated = await ProgressCheckService.completeProgressCheck(
                        user.contents,
                        program.ID,
                        evidence.evidenceJSON.programInfo.progressCheckID
                      );
                    } else {
                      if (EvidenceUtils.evidencePartTriggerFound(evidenceParts)) {
                        const operation = EvidenceUtils.evidencePartTriggerUsed(evidenceParts, evidenceJSON)
                          ? "Completed"
                          : "OnHoldReset";
                        await ProgressCheckService.createProgressCheck(
                          user.contents,
                          program.ID,
                          evidence.evidenceJSON.programInfo.progressCheckID,
                          operation
                        );
                      } else {
                        progressCheckCreated = await ProgressCheckService.createProgressCheck(
                          user.contents,
                          program.ID,
                          evidence.evidenceJSON.programInfo.progressCheckID,
                          "In-Progress"
                        );
                      }
                    }

                    progressCheckCreated && EventRegister.emit("progress-check/created", user.contents);
                  } else {
                    // update existing progress check

                    // Do special check for CSM because we want to avoid email spam from server (informing users of complete progress checks).
                    // CSM is the only module that can receive evidence after the review is done
                    if (!ProgramUtils.isCSM(program.ID) || progressCheckStatus.submissions.status !== "Completed") {
                      if (ProgramUtils.autoCompleteProgressCheckDependsOnTrigger(program.ID)) {
                        if (EvidenceUtils.evidencePartTriggerFound(evidenceParts) && !isDraftEvidence) {
                          const operation = EvidenceUtils.evidencePartTriggerUsed(evidenceParts, evidenceJSON)
                            ? "Completed"
                            : "OnHoldReset";
                          await ProgressCheckService.createProgressCheck(
                            user.contents,
                            program.ID,
                            evidence.evidenceJSON.programInfo.progressCheckID,
                            operation
                          );
                        } else {
                          let operation = progressCheckStatus.submissions.status;
                          if (!isDraftEvidence && operation === "OnHoldReset") {
                            operation = "In-Progress";
                          }
                          await ProgressCheckService.createProgressCheck(
                            user.contents,
                            program.ID,
                            evidence.evidenceJSON.programInfo.progressCheckID,
                            operation
                          );
                        }
                        EventRegister.emit("program/refresh-button", user.contents);
                      } else if (progressCheck?.Approval === ProgressCheckApproval.NotRequired) {
                        const evidenceForProgressCheck = ProgramUtils.getEvidenceForProgressCheck(
                          allEvidence,
                          evidence.evidenceJSON.programInfo.progressCheckID
                        );

                        if (
                          ProgramUtils.isAllEvidenceAddedForProgressCheck(
                            evidenceForProgressCheck,
                            progressCheck["Competence/Activity"]
                          )
                        ) {
                          const progressCheckCompleted = await ProgressCheckService.completeProgressCheck(
                            user.contents,
                            program.ID,
                            evidence.evidenceJSON.programInfo.progressCheckID
                          );

                          progressCheckCompleted && EventRegister.emit("progress-check/created", user.contents);
                        }
                      }
                    }
                  }
                }
              }

              if (isDraftEvidence) {
                setId(evidence.id);
                setSavingDraft(false);
                setIsDraft(true);
                setIsNewEvidence(false);
                setEvidence(allEvidence);
                EventRegister.emit("evidence/draft-saved");
              } else {
                setSaving(false);
                setEvidence(allEvidence);
                dismissModal();
              }
            } else {
              if (isDraftEvidence) {
                setSavingDraft(false);
              } else {
                setSaving(false);
              }
              console.log("addToDB", addToDB);

              window.alert("An error occurred when saving evidence");
            }
          } catch (error) {
            if (isDraftEvidence) {
              setSavingDraft(false);
            } else {
              setSaving(false);
            }
            console.log(error);
            window.alert("An error occurred when saving evidence");
          }
        } else {
          let evidence: IEvidence = {
            title: title.replace(/'/gim, "''"),
            date: date.toISOString(),
            activityDate: activityDate.toISOString(),
            ...(activity && { activity }),
            ...(numberOfHours !== "" && {
              numberOfHours: DataController.convertToNumberOfHours(numberOfHours),
            }),
            ...(standardTags.length > 0 && { standardTags }),
            ...(customTags.length > 0 && {
              customTags: customTags.map((item) => ({
                tag: item.tag.replace(/'/gim, "''"),
              })),
            }),
            ...(attachments.length > 0 && { attachments }),
            ...(activityDescription !== "" && {
              activityDescription: activityDescription.replace(/'/gim, "''"),
            }),
            benefits: benefits.replace(/'/gim, "''"),
            reflections: reflections.replace(/'/gim, "''"),
            ...(objectives !== "" && {
              objectives: objectives.replace(/'/gim, "''"),
            }),
            ...(addToHCPCAudit !== undefined && { addToHCPCAudit }),
            ...(timelineReference !== "" && { timelineReference }),
            id: id || DataController.generateUniqueID(),
            draft: isDraftEvidence ?? false,
          };

          if (isDraftEvidence && EvidenceUtils.isEvidenceTitleBlank(evidence.title)) {
            const draftsLength = EvidenceUtils.getDraftsCount(_e.contents);

            evidence.title = `My Draft #${draftsLength ? draftsLength + 1 : "1"}`;
            setTitle(evidence.title);
          }

          try {
            if (attachmentsToAdd.length > 0) {
              let _attachments = evidence.attachments && _.clone(evidence.attachments);
              setHandlingAttachments(true);

              const uploaded = await uploadAttachments(attachmentsToAdd, evidence.id);

              for (let i = 0; i < uploaded.length; i++) {
                let index = _attachments?.findIndex((item: EvidenceAttachment) => item.name === uploaded[i].name);

                if (_attachments && typeof index === "number" && index > -1) {
                  _attachments[index] = uploaded[i];
                  // console.log(index, uploaded[i], _attachments);
                }
              }

              // console.log('(after uploading)', _attachments);

              evidence = {
                ...evidence,
                attachments: _attachments,
              };
            }
          } catch (error) {
            console.log(error);
          }

          try {
            if (attachmentsToDelete.length > 0) {
              let _attachments = evidence.attachments && _.clone(evidence.attachments);
              setHandlingAttachments(true);

              const deleted = await deleteAttachments(attachmentsToDelete, evidence.id);

              for (let i = 0; i < deleted.length; i++) {
                let index = _attachments?.findIndex((item: EvidenceAttachment) => item.name === deleted[i]);

                if (typeof index === "number" && index > -1) {
                  _attachments = _attachments?.splice(index, 1);
                }
              }

              evidence = {
                ...evidence,
                attachments: _attachments,
              };
            }
          } catch (error) {
            console.log(error);
          }

          // console.log('(after uploading evidence to s3)', evidence);

          try {
            await FirebaseService.logEvent("evidence_added", {
              activity: activity?.type,
              ...(evidence.attachments && {
                has_attachments: true,
                attachments: evidence.attachments.length,
              }),
              ...(evidence.standardTags &&
                evidence.standardTags.length > 0 && {
                  standards: JSON.stringify(evidence.standardTags.map((item) => item.tag)),
                }),
              ...(evidence.customTags &&
                evidence.customTags.length > 0 && {
                  tags: JSON.stringify(evidence.customTags.map((item) => item.tag)),
                }),
              hasReflection: reflections.length > 0,
              hasBenefits: benefits.length > 0,
              hasLearningObjectives: objectives.length > 0,
              includedInAudit: addToHCPCAudit,
              userTrust: user.contents?.userData.CFRTrust,
              timelineReference: evidence.timelineReference,
              draft: isDraftEvidence ?? false,
            });
          } catch (error) {
            console.log(error);
          }

          if (!evidence.attachments) {
            // console.log('NO ATTACHMENTS');
          }

          setHandlingAttachments(false);

          try {
            let addToDB = await DatabaseService.addEvidence(user.contents, evidence);

            if (!addToDB) {
              addToDB = await DatabaseService.updateEvidence(user.contents, evidence);
            }

            if (addToDB) {
              let allEvidence = [];
              allEvidence = await DatabaseService.getEvidence(user.contents);
              await DataController.saveEvidence(allEvidence);

              if (isDraftEvidence) {
                setId(evidence.id);
                setSavingDraft(false);
                setIsDraft(true);
                setIsNewEvidence(false);
                setEvidence(allEvidence);
                EventRegister.emit("evidence/draft-saved");
              } else {
                setSaving(false);
                setEvidence(allEvidence);
                dismissModal();
              }
            } else {
              if (isDraftEvidence) {
                setSavingDraft(false);
              } else {
                setSaving(false);
              }
              window.alert("An error occurred when saving evidence");
            }
          } catch (error) {
            if (isDraftEvidence) {
              setSavingDraft(false);
            } else {
              setSaving(false);
            }
            window.alert("An error occurred when saving evidence");
          }
        }
      }
    } catch (error) {
      if (isDraftEvidence) {
        setSavingDraft(false);
      } else {
        setSaving(false);
      }
      console.log(error);
      window.alert("An error occurred when saving evidence");
    }
  };

  const deleteClicked = async (): Promise<void> => {
    setDeleteEvidenceModalVisible(true);
  };

  const saveDraftClicked = async (): Promise<void> => {
    try {
      await saveEvidence(true);
    } catch (error) {
      console.log(error);
    }
  };

  const saveDraftIfChanged = async (): Promise<void> => {
    if (isDraft) {
      await saveEvidence(true);
    } else if (isNewEvidence) {
      setCanSaveDraft(true);
    }
  };

  const saveEvidenceAndClose = async (): Promise<void> => {
    await saveEvidence();
    dismissModal();
  };

  const saveDraftAndClose = async (): Promise<void> => {
    await saveDraftClicked();
    dismissModal();
  };

  const deleteEvidenceAndClose = async (): Promise<void> => {
    await deleteEvidence();
    dismissModal();
  };

  const draftCloseClicked = async (): Promise<void> => {
    await saveDraftAndClose();
  };

  const newEvidenceCloseClicked = (): void => {
    if (canSaveDraft) {
      setSaveDraftModalVisible(true);
    } else {
      dismissModal();
    }
  };

  const cancelClicked = (): void => {
    if (program) {
      if (_.isEmpty(evidenceJSON)) {
        dismissModal();
      } else {
        if (!_.isEmpty(initialEvidenceJSON)) {
          let tempJSON = _.cloneDeep(evidenceJSON);

          // Find values that are dates and convert to ISO string
          // that database holds before checking equality
          let dates = Object.keys(tempJSON).filter((key) => tempJSON[key] instanceof Date);

          for (let i = 0; i < dates.length; i++) {
            const key = dates[i];
            tempJSON[key] = tempJSON[key].toISOString();

            if (isSameDay(new Date(tempJSON[key]), new Date(initialEvidenceJSON[key]))) {
              tempJSON[key] = initialEvidenceJSON[key];
            }
          }

          // console.log(tempJSON, initialEvidenceJSON);

          if (_.isEqual(initialEvidenceJSON, tempJSON)) {
            dismissModal();
          } else {
            setUnsavedChangesModalVisible(true);
          }
        } else {
          dismissModal();
        }
      }
    } else {
      if (initialEvidence) {
        const evidence: IEvidence = {
          title,
          date: date.toISOString(),
          activityDate: activityDate.toISOString(),
          ...(activity && { activity }),
          ...(numberOfHours !== "" && {
            numberOfHours: DataController.convertToNumberOfHours(numberOfHours),
          }),
          standardTags: standardTags.length > 0 ? standardTags : [],
          customTags: customTags.length > 0 ? customTags : [],
          attachments: attachments.length > 0 ? attachments : [],
          reflections,
          ...(activityDescription !== "" && {
            activityDescription: activityDescription.replace(/'/gim, "''"),
          }),
          benefits,
          ...(objectives && { objectives }),
          ...(addToHCPCAudit !== undefined && { addToHCPCAudit }),
          ...(timelineReference !== "" && { timelineReference }),
          id: id || DataController.generateUniqueID(),
        };

        if (typeof initialEvidence.attachments === "undefined" && evidence.attachments?.length === 0) {
          delete evidence.attachments;
        }

        if (typeof initialEvidence.standardTags === "undefined" && evidence.standardTags?.length === 0) {
          delete evidence.standardTags;
        }

        if (typeof initialEvidence.addToHCPCAudit === "undefined" && !evidence.addToHCPCAudit) {
          delete evidence.addToHCPCAudit;
        }

        if (typeof initialEvidence.customTags === "undefined" && evidence.customTags?.length === 0) {
          delete evidence.customTags;
        }

        const noChanges = _.isEqual(initialEvidence, evidence);

        if (!noChanges) {
          if (allFieldsFilled()) {
            setUnsavedChangesModalVisible(true);
          } else {
            setSaveDraftModalVisible(true);
          }
        } else {
          dismissModal();
        }
      } else {
        const fieldsFilled =
          title.trim().length > 0 ||
          (activity?.type !== undefined && !_.isEmpty(activity)) ||
          standardTags.length > 0 ||
          customTags.length > 0 ||
          attachments.length > 0 ||
          reflections !== "" ||
          benefits !== "" ||
          objectives !== "";

        if (fieldsFilled) {
          setSaveDraftModalVisible(true);
        } else {
          dismissModal();
        }
      }
    }
  };

  const dismissModal = (): void => {
    closeInternalDialogs();
    closeModal(false);
  };

  const closeInternalDialogs = (): void => {
    setSaveDraftModalVisible(false);
    setEmptyTagModalVisible(false);
    setExistingTagModalVisible(false);
    setUnsavedChangesModalVisible(false);
    setDeleteEvidenceModalVisible(false);
  };

  const resetModalState = (): void => {
    setTitle("");
    setDate(new Date());
    setActivityDate(new Date());
    setActivity(null);
    setNumberOfHours("");
    setStandardTags([]);
    setStandardTag("");
    setCustomTags([]);
    setAttachments([]);
    setAttachmentsToAdd([]);
    setAttachmentsToDelete([]);
    setCustomTag("");
    setActivityDescription("");
    setReflection("");
    setBenefits("");
    setObjectives("");
    setAddToHCPCAudit(false);
    setTimelineReference("");
    setId("");
    setInitialEvidence(null);
    setSaving(false);
    setSavingDraft(false);
    setCanSaveDraft(false);
    setIsNewEvidence(true);
    setIsDraft(false);
    setTempInput("");
    setSaveDraftModalVisible(false);
    setDeleteEvidenceModalVisible(false);
    setEmptyTagModalVisible(false);
    setExistingTagModalVisible(false);
    setProgram(null);
    setCompetence(null);
    setEnhancedSkill(null);
    setCompetences([]);
    setEvidenceParts([]);
    setProgressCheck(null);
    setProgressChecks([]);
    setProgressCheckCompetences([]);
    setProgressCheckCompetence(null);
    setProgramMandatoryStandards([]);
    setProgramOptionalStandards([]);
    setEvidenceJSON({});
    setInitialEvidenceJSON({});
  };

  const discardDraft = () => {
    FirebaseService.logEvent("draft_evidence_discarded", {});
    dismissModal();
  };

  const openDatePicker = (): void => {
    setDatePickerVisible(true);
  };

  const openActivityDatePicker = (): void => {
    setActivityDatePickerVisible(true);
  };

  const onDateChanged = (event: any): void => {
    setDate(new Date(event.detail.value));
    saveDraftIfChanged();
  };

  const onActivityDateChanged = (event: any): void => {
    setActivityDate(new Date(event.detail.value));
    saveDraftIfChanged();
  };

  const viewAttachment = async (attachment: EvidenceAttachment): Promise<void> => {
    try {
      if (attachment.url && user.contents) {
        setViewingAttachment(true);
        setViewingAttachmentFilename(attachment.name);

        await FileUtils.viewAttachment(user.contents, id, attachment);

        setViewingAttachment(false);
        setViewingAttachmentFilename("");
      }
    } catch (error) {
      console.log(error);
      setViewingAttachment(false);
      setViewingAttachmentFilename("");
      window.alert("An error occurred when opening attachment");
    }
  };

  const deleteAttachment = (name: string): void => {
    const index = attachments.findIndex((attachment: EvidenceAttachment) => attachment?.name === name);

    if (index > -1) {
      const attachment = attachments[index];

      if (attachment.url) {
        const toDelete = _.cloneDeep(attachmentsToDelete);
        toDelete.push(attachment);

        setAttachmentsToDelete(toDelete);
      } else if (attachment.content) {
        const toAdd = _.cloneDeep(attachmentsToAdd);
        const addedIndex = _.findIndex(toAdd, (item) => item.name === name);
        addedIndex > -1 && toAdd.splice(addedIndex);

        setAttachmentsToAdd(toAdd);
      }
    }

    index !== -1 && setAttachments(attachments.filter((_, i) => i !== index));
  };

  const addStandardTag = (code: string, type: string): void => {
    const tag: EvidenceStandardTag = { tag: code };
    setStandardTags((tags) => [...tags, tag]);
    setStandardTag("");
    standardTagsRef.current?.focus();
    saveDraftIfChanged();
    removeStandardTagFromList(code);
  };

  const deleteStandardTag = (value: string): void => {
    const index = standardTags.findIndex((tag: EvidenceStandardTag) => tag.tag === value);

    index !== -1 && setStandardTags(standardTags.filter((_, i) => i !== index));
    addStandardTagToList(value);
    saveDraftIfChanged();
  };

  const deleteCustomTag = (value: string): void => {
    const index = customTags.findIndex((tag: EvidenceCustomTag) => tag.tag === value);

    index !== -1 && setCustomTags(customTags.filter((_, i) => i !== index));
    saveDraftIfChanged();
  };

  const reflectionsEditorFocused = (): void => {
    setReflectionsFocused(true);
    setTempInput(reflections);
    const editor = document.getElementById("reflectionsEditorRef");
    editor && editor.scrollIntoView();
  };

  const reflectionsEditorBlurred = (): void => {
    setReflectionsFocused(false);

    if (tempInput !== reflections) {
      saveDraftIfChanged();
    }

    setTempInput("");
  };

  const benefitsEditorFocused = (): void => {
    setBenefitsFocused(true);
    setTempInput(benefits);
    const editor = document.getElementById("benefitsEditorRef");
    editor && editor.scrollIntoView();
  };

  const benefitsEditorBlurred = (): void => {
    setBenefitsFocused(false);

    if (tempInput !== reflections) {
      saveDraftIfChanged();
    }

    setTempInput("");
  };

  const objectivesEditorFocused = (): void => {
    setObjectivesFocused(true);
    setTempInput(objectives);
    const editor = document.getElementById("objectivesEditorRef");
    editor && editor.scrollIntoView();
  };

  const objectivesEditorBlurred = (): void => {
    setObjectivesFocused(false);

    if (tempInput !== objectives) {
      saveDraftIfChanged();
    }

    setTempInput("");
  };

  const titleChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value);
    isNewEvidence && setCanSaveDraft(true);
  };

  const titleFocused = (event: React.FocusEvent<HTMLInputElement>): void => {
    setTempInput(event.target.value);
  };

  const titleBlurred = (): void => {
    if (tempInput !== title) {
      saveDraftIfChanged();
    }

    setTempInput("");
  };

  const activityChanged = (event: any): void => {
    event.detail.value && setActivity({ type: event.detail.value });
    saveDraftIfChanged();
  };

  const standardTagChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setStandardTag(event.target.value);
    isNewEvidence && setCanSaveDraft(true);
    filterStandardTags(event.target.value);
  };

  const filterStandardTags = useCallback(
    (search: string): void => {
      const result = EvidenceUtils.filterStandardTags(search, standardTags, mandatoryStandards, optionalStandards);

      result.mandatory && setMandatoryStandardsFiltered(_.orderBy(result.mandatory, ["Code"], ["asc"]));
      result.optional && setOptionalStandardsFiltered(_.orderBy(result.optional, ["Code"], ["asc"]));
    },
    [mandatoryStandards, optionalStandards, standardTags]
  );

  const removeStandardTagFromList = useCallback(
    (code: string): void => {
      let addedTags = standardTags.map((item) => item.tag);

      let mandatory = mandatoryStandards?.filter((item) => item.Code !== code && !addedTags.includes(item.Code));
      let optional = optionalStandards?.filter((item) => item.Code !== code && !addedTags.includes(item.Code));

      mandatory && setMandatoryStandardsFiltered(_.orderBy(mandatory, ["Code"], ["asc"]));
      optional && setOptionalStandardsFiltered(_.orderBy(optional, ["Code"], ["asc"]));
    },
    [mandatoryStandards, optionalStandards, standardTags]
  );

  const customTagChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCustomTag(event.target.value);
  };

  const addStandardTagToList = useCallback(
    (code: string): void => {
      let addedTags = standardTags.map((item) => item.tag);
      addedTags = addedTags.filter((item) => item !== code);

      let mandatory = mandatoryStandards?.filter((item) => !addedTags.includes(item.Code));
      let optional = optionalStandards?.filter((item) => !addedTags.includes(item.Code));

      mandatory && setMandatoryStandardsFiltered(_.orderBy(mandatory, ["Code"], ["asc"]));
      optional && setOptionalStandardsFiltered(_.orderBy(optional, ["Code"], ["asc"]));
    },
    [mandatoryStandards, optionalStandards, standardTags]
  );

  const standardTagKeyPressed = (event: React.KeyboardEvent): void => {
    event.preventDefault();

    if (event.key === "Enter") {
    } else if (event.key === "Escape") {
      standardTagsRef.current?.blur();
      setShowStandardTags(false);
    }
  };

  const activityDurationMask = useMaskito({
    options: {
      mask: [/\d/, /\d/, ":", /\d/, /\d/],
    },
  });

  const numberOfHoursKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      setTimeout(() => {
        try {
          if (standardTagsRef.current) {
            standardTagsRef.current?.focus();
          } else if (customTagsRef.current) {
            customTagsRef.current?.focus();
          }
        } catch (error) {
          console.log(error);
        }
      }, 100);
    }
  };

  const numberOfHoursBlurred = (event: any): void => {
    const input = event.target.value;

    if (input.length > 0) {
      setNumberOfHours(EvidenceUtils.handleNumberOfHours(input));

      if (input !== numberOfHours) {
        saveDraftIfChanged();
      }
    }
  };

  const activityDescriptionChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setActivityDescription(event.target.value);
    isNewEvidence && setCanSaveDraft(true);
  };

  const activityDescriptionFocused = (event: React.FocusEvent<HTMLInputElement>): void => {
    setTempInput(event.target.value);
  };

  const activityDescriptionBlurred = (): void => {
    if (tempInput !== activityDescription) {
      saveDraftIfChanged();
    }

    setTempInput("");
  };

  const standardTagFocused = (event: React.FocusEvent<HTMLInputElement>): void => {
    setShowStandardTags(true);
  };

  const customTagKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      if (customTag.trim() === "") {
        setEmptyTagModalVisible(true);
      } else if (!customTags.find((tag) => tag.tag.toUpperCase() === customTag.toUpperCase().trim())) {
        setCustomTags((tags) => [...tags, { tag: customTag.trim() }]);
        setCustomTag("");
        saveDraftIfChanged();
      } else {
        setExistingTagModalVisible(true);
      }
    }
  };

  const allFieldsFilled = (): boolean => {
    if (program) {
      let mandatory: EvidencePart[] = _.cloneDeep(evidenceParts) || [];
      mandatory = mandatory.filter((item) => item?.options === "Mandatory" || item?.["option type"] === "Mandatory");
      mandatory = mandatory.filter((item) => EvidenceUtils.checkIfEvidencePartIsRequired(item, evidenceJSON));

      let array = [];

      for (let i = 0; i < mandatory.length; i++) {
        let part = mandatory[i];
        let value = evidenceJSON[`${part.Name}`];

        if (part["Field type"] === FieldType.NOSSelect) {
          array.push(EvidenceUtils.checkNOSSelectIsFilled(evidenceJSON));
        } else if (
          part["Field type"] === FieldType.LearningOutcomesList ||
          part["Field type"] === FieldType.LearningOutcomesListSegmented
        ) {
          array.push(EvidenceUtils.checkLearningOutcomesIsFilled(evidenceJSON));
        } else {
          array.push(DataController.checkEvidencePartIsFilled(part["Field type"] ?? "", value));
        }
      }

      return array.length > 0 ? !array.includes(false) : false;
    }

    return (
      title.length > 0 &&
      date &&
      activity !== null &&
      !_.isEmpty(activity) &&
      (numberOfHours.length === 0 || numberOfHours.length === 5) &&
      reflections !== "" &&
      benefits !== ""
    );
  };

  const disableFocusTrap = (): boolean => {
    return reflectionsFocused || benefitsFocused || objectivesFocused || richTextBoxFocused;
  };

  const canDismiss = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      if (program) {
        if (_.isEmpty(evidenceJSON)) {
          resolve(true);
        } else {
          if (!_.isEmpty(initialEvidenceJSON)) {
            let tempJSON = _.cloneDeep(evidenceJSON);

            // Find values that are dates and convert to ISO string
            // that database holds before checking equality
            let dates = Object.keys(tempJSON).filter((key) => tempJSON[key] instanceof Date);

            for (let i = 0; i < dates.length; i++) {
              const key = dates[i];
              tempJSON[key] = tempJSON[key].toISOString();

              if (isSameDay(new Date(tempJSON[key]), new Date(initialEvidenceJSON[key]))) {
                tempJSON[key] = initialEvidenceJSON[key];
              }
            }

            resolve(true);
          } else {
            resolve(true);
          }
        }
      } else {
        if (initialEvidence) {
          const evidence: IEvidence = {
            title,
            date: date.toISOString(),
            activityDate: activityDate.toISOString(),
            ...(activity && { activity }),
            ...(numberOfHours !== "" && {
              numberOfHours: DataController.convertToHoursMinutes(numberOfHours),
            }),
            standardTags: standardTags.length > 0 ? standardTags : [],
            customTags: customTags.length > 0 ? customTags : [],
            attachments: attachments.length > 0 ? attachments : [],
            reflections,
            ...(activityDescription !== "" && {
              activityDescription: activityDescription.replace(/'/gim, "''"),
            }),
            benefits,
            ...(objectives && { objectives }),
            ...(addToHCPCAudit !== undefined && { addToHCPCAudit }),
            ...(timelineReference !== "" && { timelineReference }),
            id: id || DataController.generateUniqueID(),
          };

          if (typeof initialEvidence.attachments === "undefined" && evidence.attachments?.length === 0) {
            delete evidence.attachments;
          }

          if (typeof initialEvidence.standardTags === "undefined" && evidence.standardTags?.length === 0) {
            delete evidence.standardTags;
          }

          if (typeof initialEvidence.addToHCPCAudit === "undefined" && !evidence.addToHCPCAudit) {
            delete evidence.addToHCPCAudit;
          }

          if (typeof initialEvidence.customTags === "undefined" && evidence.customTags?.length === 0) {
            delete evidence.customTags;
          }

          // console.log(initialEvidence, evidence);

          const noChanges = _.isEqual(initialEvidence, evidence);

          if (!noChanges) {
            // const action = window.confirm('You have unsaved changes in this evidence.\n\nAre you sure you want to exit without saving your changes?');

            // if (action) {
            resolve(true);
            // } else {
            // resolve(false);
            // }
          } else {
            resolve(true);
          }
        } else {
          const fieldsFilled =
            title.trim().length > 0 ||
            (activity?.type !== undefined && !_.isEmpty(activity)) ||
            standardTags.length > 0 ||
            customTags.length > 0 ||
            attachments.length > 0 ||
            reflections !== "" ||
            benefits !== "" ||
            objectives !== "";

          if (fieldsFilled) {
            // const action = window.confirm("You haven't saved this evidence.\n\nIf you don't save this evidence, all of your content you've added here will be lost");

            // if (action) {
            resolve(true);
            // } else {
            // resolve(false);
            // }
          } else {
            resolve(true);
          }
        }
      }
    });
  };

  // For Bespoke programs
  const enhancedSkillChanged = (item: any): void => {
    const name = item.detail.value;

    const _competence: CompetenceActivityClass = competences.find(
      (item: CompetenceActivityClass) => item.Name === name
    );

    if (_competence) {
      if (competence && competence.Name !== _competence.Name) {
        setChangeTaskingTypeModalVisible(true);

        // Set tasking type name for confirmation;
        setTempTaskingType(name);
      } else {
        setCompetence(_competence);
        isNewEvidence && setCanSaveDraft(true);
        _competence["Evidence Definitions"] ? getEvidenceParts(_competence) : setEvidenceParts([]);
      }
    }
  };

  const progressCheckChanged = (item: any): void => {
    const name = item.detail.value;

    const _progressCheck: ProgressCheck = progressChecks.find((item: ProgressCheck) => item.Name === name);

    if (_progressCheck) {
      if (progressCheck && progressCheck.Name !== _progressCheck.Name) {
        setChangeProgressCheckModalVisible(true);

        setTempProgressCheck(name);
      } else {
        setProgressCheck(_progressCheck);
        setCanSaveDraft(false);
        _progressCheck["Competence/Activity"]
          ? getProgressCheckCompetences(_progressCheck)
          : setProgressCheckCompetences([]);
      }
    }
  };

  const progressCheckCompetenceChanged = (item: any): any => {
    const name = item.detail.value;

    const _progressCheckCompetence: CompetenceActivityClass = progressCheckCompetences.find(
      (item: CompetenceActivityClass) => item.Name === name
    );

    if (_progressCheckCompetence) {
      if (progressCheckCompetence && progressCheckCompetence.Name !== _progressCheckCompetence.Name) {
        setChangeProgressCheckCompetenceModalVisible(true);

        setTempProgressCheckCompetence(name);
      } else {
        setProgressCheckCompetence(_progressCheckCompetence);
        isNewEvidence && setCanSaveDraft(true);
        getEvidenceParts(_progressCheckCompetence);
      }
    }
  };

  const confirmChangeProgressCheck = (name: string): void => {
    if (name.length > 0) {
      const _progressCheck: ProgressCheck = progressChecks.find((item: ProgressCheck) => item.Name === name);

      setCanSaveDraft(false);
      setProgressCheck(_progressCheck);
      setEvidenceParts([]);
      setProgressCheckCompetence(null);
      resetEvidenceAfterTypeChanged();
      _progressCheck["Competence/Activity"]
        ? getProgressCheckCompetences(_progressCheck)
        : setProgressCheckCompetences([]);
    }
  };

  const confirmChangeProgressCheckCompetence = (name: string): void => {
    if (name.length > 0) {
      const _progressCheckCompetence: CompetenceActivityClass = progressCheckCompetences.find(
        (item: CompetenceActivityClass) => item.Name === name
      );

      setProgressCheckCompetence(_progressCheckCompetence);
      resetEvidenceAfterTypeChanged();
      getEvidenceParts(_progressCheckCompetence);
    }
  };

  const confirmChangeTaskType = (name: string) => {
    if (name.length > 0) {
      const _competence: CompetenceActivityClass = competences.find(
        (item: CompetenceActivityClass) => item.Name === name
      );

      setCompetence(_competence);
      resetEvidenceAfterTypeChanged();
      _competence["Evidence Definitions"] ? getEvidenceParts(_competence) : setEvidenceParts([]);
    }
  };

  const resetEvidenceAfterTypeChanged = (): void => {
    setEvidenceJSON({});
    setNumberOfHours("");
    setAddToHCPCAudit(false);
    setChangeProgressCheckModalVisible(false);
    setChangeProgressCheckCompetenceModalVisible(false);
    setChangeTaskingTypeModalVisible(false);
  };

  const getEvidenceParts = (_competence: CompetenceActivityClass): void => {
    const evidenceDefinitions = _competence?.["Evidence Definitions"][0];
    const _evidenceParts = EvidenceUtils.getEvidenceParts(evidenceDefinitions);

    setEvidenceParts(_evidenceParts);
  };

  const toggleAuditTickbox = (): void => {
    setAddToHCPCAudit(!addToHCPCAudit);
    saveDraftIfChanged();
  };

  const blurRichText = (): void => {
    setRichTextBoxFocused(false);
    saveDraftIfChanged();
  };

  const evidencePartChanged = (evidencePart: EvidencePart, value: any, isInput?: boolean): void => {
    let object = _.cloneDeep(evidenceJSON);
    let key = evidencePart.Name;

    let newObject: any;

    if (evidencePart["Field type"] === FieldType.Attachments) {
      newObject = _.extend(object, {
        [key]: {
          ...object[key],
          ...value,
        },
      });
    } else if (evidencePart["Field type"] === FieldType.NOSSelect) {
      newObject = _.extend(object, value);
    } else if (evidencePart["Field type"] === FieldType.CompMultiSelect) {
      newObject = _.extend(object, { "Comp Selector": value });
    } else if (evidencePart["Field type"] === FieldType.LearningOutcomesList) {
      newObject = _.extend(object, { LearningOutcomes: value });
    } else {
      newObject = _.extend(object, { [key]: value });
    }

    if (evidencePart["Linked Part"] && evidencePart["Linked Part"][0]) {
      const linkedPart = evidencePart["Linked Part"][0];
      const triggers = linkedPart["Linked Part trigger"] ? linkedPart["Linked Part trigger"].split("\n") : [];
      if (!triggers.includes(value)) {
        // change newObj so that the key of first.Name is removed
        newObject = _.omit(newObject, linkedPart.Name);
      }
    }

    setEvidenceJSON((e: any) => ({ ...e, ...newObject }));

    if (evidencePart["Field type"] === FieldType.ActivityDuration) {
      setNumberOfHours(value);
    }

    if (isValid(value) && DataController.isDefaultDate(evidencePart.Name, program)) {
      setDate(value);
    }

    if (!isInput) {
      saveDraftIfChanged();
    }
  };

  return (
    <IonModal
      id="evidenceModal"
      isOpen={modalVisible}
      onDidDismiss={() => resetModalState()}
      backdropDismiss={true}
      canDismiss={canDismiss}
      className={`fullscreen ${disableFocusTrap() ? "ion-disable-focus-trap" : ""}`}
    >
      <IonHeader
        style={{
          height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
        }}
      >
        {isNewEvidence ? (
          <IonToolbar mode="ios" className="navBar" style={{ maxWidth: DataController.isWeb() ? 980 : undefined }}>
            <IonButtons slot="start">
              <IonButton mode="ios" className="cancelButton" onClick={() => newEvidenceCloseClicked()}>
                {"Close"}
              </IonButton>
            </IonButtons>
            <IonTitle>{"New Evidence"}</IonTitle>
            {canSaveDraft && (
              <IonButtons slot="end">
                {!savingDraft ? (
                  <IonButton mode="ios" className="cancelButton" onClick={() => saveDraftClicked()}>
                    <div className="font-semibold">{"Save draft"}</div>
                  </IonButton>
                ) : (
                  <div className="flex justify-center items-center">
                    <IonSpinner className="w-[20px] h-[20px] text-white" />
                  </div>
                )}
              </IonButtons>
            )}
          </IonToolbar>
        ) : isDraft ? (
          <IonToolbar mode="ios" className="navBar" style={{ maxWidth: DataController.isWeb() ? 980 : undefined }}>
            <IonButtons slot="start">
              <IonButton mode="ios" className="cancelButton" onClick={() => draftCloseClicked()}>
                {"Close"}
              </IonButton>
            </IonButtons>
            <IonTitle>{"Draft"}</IonTitle>
            <IonButtons slot="end">
              <IonButton mode="ios" className="cancelButton" onClick={() => deleteClicked()}>
                {"Delete"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        ) : (
          <IonToolbar mode="ios" className="navBar" style={{ maxWidth: DataController.isWeb() ? 980 : undefined }}>
            <IonButtons slot="start">
              <IonButton mode="ios" className="cancelButton" onClick={() => cancelClicked()}>
                {"Close"}
              </IonButton>
            </IonButtons>
            <IonTitle>{"Edit Evidence"}</IonTitle>
            <IonButtons slot="end">
              <IonButton mode="ios" className="cancelButton" onClick={() => deleteClicked()}>
                {"Delete"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent>
        <ContentContainer
          style={{
            paddingBottom: keyboardActive && disableFocusTrap() ? keyboardHeight : 12,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SaveDraftModal
            visible={saveDraftModalVisible}
            cancelPressed={() => setSaveDraftModalVisible(false)}
            deletePressed={() => discardDraft()}
            savePressed={() => saveDraftAndClose()}
          />
          <DeleteEvidenceModal
            visible={deleteEvidenceModalVisible}
            isDraft={isDraft}
            cancelPressed={() => setDeleteEvidenceModalVisible(false)}
            deletePressed={() => deleteEvidenceAndClose()}
          />
          <EmptyTagModal visible={emptyTagModalVisible} dismissPressed={() => setEmptyTagModalVisible(false)} />
          <ExistingTagModal
            visible={existingTagModalVisible}
            dismissPressed={() => setExistingTagModalVisible(false)}
          />
          <UnsavedChangesModal
            visible={unsavedChangesModalVisible}
            cancelPressed={() => setUnsavedChangesModalVisible(false)}
            discardPressed={() => dismissModal()}
            savePressed={() => saveEvidenceAndClose()}
            disableSaveChangesButton={!allFieldsFilled()}
          />
          {program ? (
            <>
              {/* <pre>{JSON.stringify(evidenceJSON, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(progressCheck, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(changeProgressCheckModal, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(changeTaskingTypeModalVisible, null, 2)}</pre> */}
              {/* <pre>evidenceParts: {JSON.stringify(evidenceParts, null, 2)}</pre> */}
              {/* <pre>progressChecks: {JSON.stringify(progressChecks, null, 2)}</pre> */}
              {/* <pre>program: {JSON.stringify(program, null, 2)}</pre> */}
              {/* <pre>competences: {JSON.stringify(competences, null, 2)}</pre> */}
              {/* <pre>progressCheckCompetences: {JSON.stringify(progressCheckCompetence, null, 2)}</pre> */}
              {/* <pre>isDraft: {JSON.stringify(isDraft, null, 2)}</pre> */}
              <IonModal
                id="changeProgressCheckModal"
                isOpen={changeProgressCheckModal}
                backdropDismiss={true}
                canDismiss={true}
                className="fullscreen"
                onDidDismiss={() => setChangeProgressCheckModalVisible(false)}
              >
                <IonContent className="changeTaskingTypeModal">
                  <div className="changeTaskingTypeModalContainer">
                    <div>
                      <div className="changeTaskingTypeModalTitle">{"Are you sure you want to change the type?"}</div>
                      <div className="changeTaskingTypeModalText mx-[8px]">
                        {"Changing type will lose any information added for that type"}
                      </div>
                    </div>
                    <div className="tipBottomButtonContainer" style={{ marginTop: 20 }}>
                      <button className="tipBottomButton" onClick={() => confirmChangeProgressCheck(tempProgressCheck)}>
                        {"Change type"}
                      </button>
                    </div>
                    <div className="tipBottomButtonContainer">
                      <button className="tipBottomButton" onClick={() => setChangeProgressCheckModalVisible(false)}>
                        {"Keep the current type"}
                      </button>
                    </div>
                  </div>
                </IonContent>
              </IonModal>
              <IonModal
                id="changeProgressCheckModal"
                isOpen={changeProgressCheckCompetenceModal}
                backdropDismiss={true}
                canDismiss={true}
                className="fullscreen"
                onDidDismiss={() => setChangeProgressCheckCompetenceModalVisible(false)}
              >
                <IonContent className="changeTaskingTypeModal">
                  <div className="changeTaskingTypeModalContainer">
                    <div>
                      <div className="changeTaskingTypeModalTitle">{"Are you sure you want to change the type?"}</div>
                      <div className="changeTaskingTypeModalText mx-[8px]">
                        {"Changing type will lose any information added for that type"}
                      </div>
                    </div>
                    <div className="tipBottomButtonContainer" style={{ marginTop: 20 }}>
                      <button
                        className="tipBottomButton"
                        onClick={() => confirmChangeProgressCheckCompetence(tempProgressCheckCompetence)}
                      >
                        {"Change type"}
                      </button>
                    </div>
                    <div className="tipBottomButtonContainer">
                      <button
                        className="tipBottomButton"
                        onClick={() => setChangeProgressCheckCompetenceModalVisible(false)}
                      >
                        {"Keep the current type"}
                      </button>
                    </div>
                  </div>
                </IonContent>
              </IonModal>
              <IonModal
                id="changeTaskingTypeModal"
                isOpen={changeTaskingTypeModalVisible}
                backdropDismiss={true}
                canDismiss={true}
                className="fullscreen"
                onDidDismiss={() => setChangeTaskingTypeModalVisible(false)}
              >
                <IonContent className="changeTaskingTypeModal">
                  <div className="changeTaskingTypeModalContainer">
                    <div>
                      <div className="changeTaskingTypeModalTitle">
                        {"Are you sure you want to change the task type?"}
                      </div>
                      <div className="changeTaskingTypeModalText">
                        {"Changing task type will lose any information added for that task"}
                      </div>
                    </div>
                    <div className="tipBottomButtonContainer" style={{ marginTop: 20 }}>
                      <button className="tipBottomButton" onClick={() => confirmChangeTaskType(tempTaskingType)}>
                        {"Change task type"}
                      </button>
                    </div>
                    <div className="tipBottomButtonContainer">
                      <button className="tipBottomButton" onClick={() => setChangeTaskingTypeModalVisible(false)}>
                        {"Keep the current task type"}
                      </button>
                    </div>
                  </div>
                </IonContent>
              </IonModal>
              <EvidenceHeader programName={program.Title} competenceName={competence?.Name} />
              {progressChecks?.length > 0 && (
                <EvidenceContentCard title="Progress check">
                  <div className="reflectionActivityTypeContainer">
                    <IonList mode="md" className="reflectionActivityList" color="white">
                      <IonItem mode="md" className="reflectionActivityItem" color="white">
                        <IonSelect
                          color="white"
                          mode="md"
                          className="reflectionActivitySelectItem w-full"
                          interface="popover"
                          justify="space-between"
                          interfaceOptions={{
                            size: "auto",
                            alignment: "start",
                          }}
                          placeholder="Select progress check"
                          value={progressCheck?.Name || undefined}
                          onIonChange={progressCheckChanged}
                        >
                          {progressChecks.map((item: ProgressCheck) => (
                            <IonSelectOption color="white" key={item.Name} value={item.Name}>
                              {item.Name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </div>
                </EvidenceContentCard>
              )}
              {/* <div>{JSON.stringify(progressCheckCompetences, null, 2)}</div> */}
              {/* <div>{JSON.stringify(competences, null, 2)}</div> */}
              {progressCheckCompetences?.length > 0 && (
                <EvidenceContentCard title={"Evidence type"}>
                  <div className="reflectionActivityTypeContainer">
                    <IonList mode="md" className="reflectionActivityList" color="white">
                      <IonItem className="reflectionActivityItem" color="white" mode="md">
                        <IonSelect
                          color="white"
                          mode="md"
                          className="reflectionActivitySelectItem"
                          interface="popover"
                          justify="space-between"
                          interfaceOptions={{
                            size: "auto",
                            alignment: "start",
                          }}
                          placeholder="Select evidence"
                          value={progressCheckCompetence?.Name || undefined}
                          onIonChange={progressCheckCompetenceChanged}
                        >
                          {progressCheckCompetences.map((item: CompetenceActivityClass) => (
                            <IonSelectOption color="white" key={item.Name} value={item.Name}>
                              {item.Name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </div>
                </EvidenceContentCard>
              )}
              {competences?.length > 0 && !ProgramUtils.isTelephoneTriageModule(program.ID) && (
                <EvidenceContentCard title="Enhanced Skill undertaken">
                  <div className="reflectionActivityTypeContainer">
                    <IonList mode="md" className="reflectionActivityList" color="white">
                      <IonItem mode="md" className="reflectionActivityItem" color="white">
                        <IonSelect
                          color="white"
                          mode="md"
                          className="reflectionActivitySelectItem"
                          interface="popover"
                          justify="space-between"
                          interfaceOptions={{
                            size: "auto",
                            alignment: "start",
                          }}
                          placeholder="Select enhanced skill"
                          value={competence?.Name || undefined}
                          onIonChange={enhancedSkillChanged}
                        >
                          {competences.map((item: CompetenceActivityClass) => (
                            <IonSelectOption color="white" key={item.Name} value={item.Name}>
                              {item.Name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </div>
                </EvidenceContentCard>
              )}
              {evidenceParts?.map((evidencePart: EvidencePart, index) => {
                if (evidencePart["Linked Part type"] && evidencePart["Linked Part type"] === "Sibling") {
                  return null;
                }
                return (
                  <>
                    <ProgramModuleEvidencePart
                      evidencePart={evidencePart}
                      blurRichText={() => blurRichText()}
                      focusRichText={() => setRichTextBoxFocused(true)}
                      evidenceJSON={evidenceJSON}
                      index={index}
                      onInputBlurred={() => saveDraftIfChanged()}
                      onValueChanged={(value: any, isInput?: boolean) =>
                        evidencePartChanged(evidencePart, value, isInput)
                      }
                      onChildValueChanged={(part: EvidencePart, value: any, isInput?: boolean) =>
                        evidencePartChanged(part, value, isInput)
                      }
                      key={`${competence?.Name}-${evidencePart.Name}-${index}`}
                      program={program}
                      progressCheckCompetence={progressCheckCompetence}
                      mandatoryStandards={programMandatoryStandards}
                      optionalStandards={programOptionalStandards}
                      competence={competence}
                      setExistingTagModalVisible={() => setExistingTagModalVisible(true)}
                      setEmptyTagModalVisible={() => setEmptyTagModalVisible(true)}
                      learningOutcomes={progressCheckCompetence ? progressCheckCompetence["Learning Objective"] : []}
                      currentProgressCheckId={progressCheck?.ID ?? ""}
                    />
                  </>
                );
              })}
              <AuditTickbox active={addToHCPCAudit} toggleValue={() => toggleAuditTickbox()} />
            </>
          ) : (
            <>
              <EvidenceHeader programName="Generic Evidence" />
              <EvidenceContentCard title="Title (required)">
                <input
                  ref={titleRef}
                  className="reflectionTitleInput"
                  placeholder="Give your evidence a title..."
                  autoComplete="off"
                  spellCheck
                  autoCapitalize="words"
                  id="reflectionTitleInput"
                  value={title}
                  onFocus={titleFocused}
                  onChange={titleChanged}
                  onBlur={titleBlurred}
                />
              </EvidenceContentCard>
              <EvidenceContentCard title="Date of reflection (required)">
                <div id="reflectionDatePicker" className="reflectionDateContainer" onClick={() => openDatePicker()}>
                  <div className="reflectionDateText">{format(date, "dd / MM / yyyy")}</div>
                  <img src={Button_Dropdown} className="reflectionsHeaderDropdown" alt="" />
                  <IonPopover
                    trigger="reflectionDatePicker"
                    isOpen={datePickerVisible}
                    onDidDismiss={() => setDatePickerVisible(false)}
                  >
                    <IonDatetime
                      mode="md"
                      size="cover"
                      value={date.toISOString()}
                      onChange={(event) => console.log(event)}
                      onIonChange={onDateChanged}
                      max={new Date().toISOString()}
                      presentation="date"
                      firstDayOfWeek={1}
                    />
                  </IonPopover>
                </div>
              </EvidenceContentCard>
              <EvidenceContentCard title="Date of activity (required)">
                <div
                  id="activityDatePicker"
                  className="reflectionDateContainer"
                  onClick={() => openActivityDatePicker()}
                >
                  <div className="reflectionDateText">{format(activityDate, "dd / MM / yyyy")}</div>
                  <img src={Button_Dropdown} className="reflectionsHeaderDropdown" alt="" />
                  <IonPopover
                    trigger="activityDatePicker"
                    isOpen={activityDatePickerVisible}
                    onDidDismiss={() => setActivityDatePickerVisible(false)}
                  >
                    <IonDatetime
                      mode="md"
                      size="cover"
                      value={activityDate.toISOString()}
                      onChange={(event) => console.log(event)}
                      onIonChange={onActivityDateChanged}
                      max={new Date().toISOString()}
                      presentation="date"
                      firstDayOfWeek={1}
                    />
                  </IonPopover>
                </div>
              </EvidenceContentCard>
              <EvidenceContentCard title="CPD Activity (required)">
                <div className="reflectionActivityTypeContainer" onClick={() => {}}>
                  <IonList mode="md" className="reflectionActivityList" color="white">
                    <IonItem mode="md" className="reflectionActivityItem" color="white">
                      <IonSelect
                        color="white"
                        mode="md"
                        className="reflectionActivitySelectItem"
                        interface="popover"
                        justify="space-between"
                        interfaceOptions={{
                          size: "auto",
                          alignment: "start",
                        }}
                        placeholder="What type of CPD activity are you reflecting on"
                        value={activity?.type || undefined}
                        onIonChange={activityChanged}
                      >
                        {activityTypes.map((item, index) => {
                          return (
                            <IonSelectOption color="white" key={item.type} value={item.type}>
                              {item.type}
                            </IonSelectOption>
                          );
                        })}
                      </IonSelect>
                    </IonItem>
                  </IonList>
                </div>
              </EvidenceContentCard>
              <EvidenceContentCard title="Activity duration">
                <IonInput
                  ref={async (activityDurationRef) => {
                    if (activityDurationRef) {
                      const input = await activityDurationRef.getInputElement();
                      activityDurationMask(input);
                    }
                  }}
                  className="reflectionTagsInput"
                  placeholder="00:00 (HH:MM)"
                  id="reflectionNumberOfHoursInput"
                  autocomplete="off"
                  value={numberOfHours}
                  defaultValue={"00:00"}
                  onIonChange={(event) => setNumberOfHours(event.detail.value || "")}
                  onKeyDown={numberOfHoursKeyPress}
                  onIonBlur={numberOfHoursBlurred}
                  inputmode="numeric"
                />
              </EvidenceContentCard>
              {appFeatures.includes(IAppFeatures.SWAST_MIC) && (
                <EvidenceContentCard id="reflectionsStandardTags" title="National Occupational Standards Tags">
                  <div style={{ position: "relative" }}>
                    <div className="reflectionsStandardTagsContainer">
                      {standardTags.map((tag, index) => {
                        return (
                          <div
                            className="reflectionTagContainer"
                            key={tag.tag}
                            onClick={() => deleteStandardTag(tag.tag)}
                          >
                            <div className="reflectionTagText">{tag.tag}</div>
                            <img src={Icon_Attachment_Delete} className="attachmentIconClose" alt="" />
                          </div>
                        );
                      })}
                      <div>
                        <input
                          ref={standardTagsRef}
                          className="reflectionTagsInput"
                          placeholder="Start typing to find tags..."
                          id="reflectionStandardTagsInput"
                          autoComplete="off"
                          spellCheck
                          value={standardTag}
                          onChange={standardTagChanged}
                          onKeyUp={standardTagKeyPressed}
                          onFocus={standardTagFocused}
                        />
                      </div>
                    </div>
                    {showStandardTags && (
                      <div className="standardTagsPopover">
                        {mandatoryStandardsFiltered?.length === 0 && optionalStandardsFiltered?.length === 0 && (
                          <div className="standardTagPopoverEmpty">
                            <div className="standardTagPopoverEmptyText">{"No tags found"}</div>
                          </div>
                        )}
                        {mandatoryStandardsFiltered &&
                          mandatoryStandardsFiltered.map((item, index) => {
                            const isStart = index === 0;
                            const isLast = index === mandatoryStandardsFiltered.length - 1;

                            return (
                              <div key={item.id}>
                                {isStart && <div className="standardTagHeader">{"Mandatory"}</div>}
                                <div
                                  className={`standardTagButton ${isLast ? "standardTagButtonLast" : ""}`}
                                  onClick={() => addStandardTag(item.Code, "mandatory")}
                                >
                                  {`${item.Code}${item.Description ? ` - ${item.Description}` : ""}`}
                                </div>
                              </div>
                            );
                          })}
                        {optionalStandardsFiltered &&
                          optionalStandardsFiltered.map((item, index) => {
                            const isStart = index === 0;
                            const isLast = index === optionalStandardsFiltered.length - 1;

                            return (
                              <div key={item.id}>
                                {isStart && <div className="standardTagHeader">{"Optional"}</div>}
                                <div
                                  className={`standardTagButton ${isLast ? "standardTagButtonLast" : ""}`}
                                  onClick={() => addStandardTag(item.Code, "optional")}
                                >
                                  {`${item.Code}${item.Description && ` - ${item.Description}`}`}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </EvidenceContentCard>
              )}
              <EvidenceContentCard title="Custom tags">
                <div className="reflectionsCustomTagContainer">
                  {customTags.map((tag, index) => {
                    return (
                      <div className="reflectionTagContainer" key={tag.tag} onClick={() => deleteCustomTag(tag.tag)}>
                        <div className="reflectionTagText">{tag.tag}</div>
                        <img src={Icon_Attachment_Delete} className="attachmentIconClose" alt="" />
                      </div>
                    );
                  })}
                  <div>
                    <input
                      ref={customTagsRef}
                      className="reflectionTagsInput"
                      placeholder="Hit return to add your custom tag..."
                      id="reflectionCustomTagsInput"
                      autoComplete="off"
                      spellCheck
                      value={customTag}
                      onChange={customTagChanged}
                      onKeyUp={customTagKeyPressed}
                    />
                  </div>
                </div>
              </EvidenceContentCard>
              <EvidenceContentCard title="Relevant documents (85MB limit / file)">
                <AttachmentInput
                  attachments={attachments}
                  viewingAttachment={viewingAttachment}
                  viewingAttachmentFilename={viewingAttachmentFilename}
                  viewAttachment={(attachment) => viewAttachment(attachment)}
                  deleteAttachment={(filename) => deleteAttachment(filename)}
                  openFileSelector={() => openFileSelector()}
                />
              </EvidenceContentCard>
              <EvidenceContentCard title="Activity description">
                <input
                  ref={activityDescriptionRef}
                  className="reflectionTagsInput"
                  placeholder="Provide a brief description..."
                  id="reflectionCustomTagsInput"
                  autoComplete="off"
                  value={activityDescription}
                  onFocus={activityDescriptionFocused}
                  onChange={activityDescriptionChanged}
                  onBlur={activityDescriptionBlurred}
                />
              </EvidenceContentCard>
              <EvidenceContentCard title="Reflection (required)">
                <Editor
                  init={EditorUtils.ReflectionsEditorConfig}
                  tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                  id="reflectionsEditorRef"
                  onInit={(event, editor) => (reflectionsEditorRef.current = editor)}
                  value={reflections}
                  onEditorChange={(a, editor) => {
                    a.length > 0 && editor.getBody().setAttribute("data-mce-placeholder", "");
                    setReflection(a);
                    isNewEvidence && setCanSaveDraft(true);
                  }}
                  onFocus={reflectionsEditorFocused}
                  onBlur={reflectionsEditorBlurred}
                />
              </EvidenceContentCard>
              <EvidenceContentCard title="Benefits (required)">
                <Editor
                  init={EditorUtils.BenefitsEditorConfig}
                  tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                  id="benefitsEditorRef"
                  onInit={(event, editor) => (benefitsEditorRef.current = editor)}
                  value={benefits}
                  onEditorChange={(a, editor) => {
                    a.length > 0 && editor.getBody().setAttribute("data-mce-placeholder", "");
                    setBenefits(a);
                    isNewEvidence && setCanSaveDraft(true);
                  }}
                  onFocus={benefitsEditorFocused}
                  onBlur={benefitsEditorBlurred}
                />
              </EvidenceContentCard>
              <EvidenceContentCard title="Learning / Exercise Objectives">
                <Editor
                  init={EditorUtils.ObjectivesEditorConfig}
                  tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                  id="objectivesEditorRef"
                  onInit={(event, editor) => (objectivesEditorRef.current = editor)}
                  value={objectives}
                  onEditorChange={(a, editor) => {
                    a.length > 0 && editor.getBody().setAttribute("data-mce-placeholder", "");
                    setObjectives(a);
                    isNewEvidence && setCanSaveDraft(true);
                  }}
                  onFocus={objectivesEditorFocused}
                  onBlur={objectivesEditorBlurred}
                />
              </EvidenceContentCard>
              <AuditTickbox active={addToHCPCAudit} toggleValue={() => toggleAuditTickbox()} />
            </>
          )}
          <AttachmentProgressIndicator visible={handlingAttachments} />
          {/* <pre>{JSON.stringify(evidenceJSON, null, 2)}</pre> */}
          <SaveEvidenceButton
            disabled={!allFieldsFilled() || saving}
            saving={saving}
            isDraft={isDraft}
            isNewEvidence={isNewEvidence}
            saveEvidence={() => saveEvidence()}
          />
        </ContentContainer>
      </IonContent>
    </IonModal>
  );
};

export default EvidenceModal;
